export const userHeaderMapping = {
  last_name: "氏名（姓）",
  first_name: "氏名（名）",
  last_furigana_name: "かな（せい）（任意）",
  first_furigana_name: "かな（めい）（任意）",
  employee_number: "社員番号（任意）",
  email: "メールアドレス（任意）",
  personal_tel: "電話番号",
  login_id: "ログインID",
  login_password: "ログインパスワード",
  is_display: "アカウント状態（有効：TRUE、無効：FALSE）",
  role: "権限　（１：一般者、　２：管理者）",
  tenant_department_id: "所属部署（任意）",
  position_name: "役職（任意）",
  apps: "利用アプリ",
};

export const contactHeaderMapping = {
  last_name: "氏名（姓）",
  first_name: "氏名（名）",
  last_furigana_name: "かな（せい）（任意）",
  first_furigana_name: "かな（めい）（任意）",
  email: "メールアドレス（任意）",
  personal_tel: "電話番号",
  position_name: "役職名 （任意）",
  company_name: "企業名 （任意）",
  company_tel: "企業電話番号 （任意）",
  company_home_page: "企業ホームページ （任意）",
};

export const tenantHeaderMapping = {
  id: "id",
  code: "コード",
  name: "企業名 ",
  name_kana: "企業名ふりがな ",
  postal_code: "郵便番号 ",
  address: "住所",
  phone_number: "電話番号",
  url: "企業URL",
  local_auth: "ログイン方法設定",
  require_passcode_for_phonebook: "電話帳参照制限",
  app_id: "APP ID",
  app_name: "APP名",
  status: "契約状態",
  contract_count: "ライセンス数",
  current_users: "利用者",
  start_date: "契約開始日",
  end_date: "契約終了日",
};

export const headerOrder = [
  "id",
  "code",
  "name",
  "name_kana",
  "last_name",
  "first_name",
  "last_furigana_name",
  "first_furigana_name",
  "employee_number",
  "postal_code",
  "address",
  "phone_number",
  "email",
  "url",
  "local_auth",
  "require_passcode_for_phonebook",
  "app_id",
  "app_name",
  "status",
  "contract_count",
  "current_users",
  "start_date",
  "end_date",
  "personal_tel",
  "login_id",
  "login_password",
  "is_display",
  "department",
  "role",
  "tenant_department_id",
  "position_name",
  "apps",
  "company_name",
  "company_tel",
  "company_address",
  "company_home_page",
];

export const translateAndReorderHeaders = (headerMapping) => {
  const reorderedHeaders = headerOrder
    .map((key) => headerMapping[key])
    .filter((header) => header !== undefined && header !== null);

  return reorderedHeaders;
};
