import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import { ConfirmCancelButtons } from "../../components/Buttons/ConfirmCancelButtons";
import PasswordForm from "../../components/Forms/PasswordForm";
import {
  resetUserPassword,
  validateUserExists,
} from "../../services/API/Requests";
import { USER } from "../../types/user";
import { TranslateErrorMessage } from "../../services/ErrorMessages";
import { GetPasswordPolicy } from "../../services/Utilities";
import { error_message, UserFieldErrorMessages } from "../../constants/Errors";
import messages from "../../constants/Messages";
import { GENERIC_PATHS } from "../../constants/NavigationPaths";

/**
 * PasswordResetPage - A component for allowing users to reset their password.
 *
 * This component provides a form for users to enter and confirm a new password.
 * It validates the input to ensure that the new password fields match and are not empty.
 *
 * State:
 * - `newPassword` (string): Stores the new password entered by the user.
 * - `confirmPassword` (string): Stores the confirmation password entered by the user.
 * - `userData` (USER | undefined): Stores the user data fetched from the backend API to verify user existence.
 * - `loading` (boolean): Indicates whether the component is in a loading state while fetching data or making requests.
 * - `error` (string): Holds error messages encountered during API interactions.
 *
 * Functions:
 * - `verifyBlankFields`: Checks if both password fields are filled.
 * - `verifyNewPassword`: Checks if the new password matches the confirmation password.
 * - `verifyPasswords`: Combines the previous two checks and triggers toast notifications based on the result.
 * - `onCancel`: Navigates back to the home page without saving any changes.
 * - `onConfirm`: If the password verification succeeds, sends the new password to the backend API for updating the user password.
 *
 * API Interactions:
 * - `validateUserExists`: Fetches user data from the backend API to ensure the user exists before allowing password changes.
 * - `resetUserPassword`: Sends a request to reset the user's password using the new password provided.
 *
 * Components:
 * - `TitleWithDivider`: A component for displaying a title with a divider.
 * - `ConfirmCancelButtons`: A component for displaying confirm and cancel buttons.
 * - `PasswordForm`: A reusable form component for entering passwords, including toggleable visibility.
 *
 * Toast Notifications:
 * - `noMatchToast`: Displays an error message if the passwords do not match.
 * - `blankFieldToast`: Displays an error message if any of the password fields are empty.
 * - `passwordChangedToast`: Displays a success message if the password has been changed successfully.
 *
 * Usage:
 * This component is used when a user wants to reset their password, typically accessible from a profile or settings page.
 *
 * Example Usage:
 * <PasswordResetPage />
 *
 * Note:
 * - The `userData` is fetched using the `validateUserExists` function with the `myUserPage` parameter set to `true`, indicating that it checks the currently logged-in user.
 * - The `id` is set to `"none"` because the user's ID is inferred from the token when `myUserPage` is `true`.
 * - The component validates that the passwords match before allowing the user to submit the form.
 */

const PasswordResetPage = ({}) => {
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [userData, setUserData] = useState<USER | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const myUserPage: boolean = true;
  const id: string = "none";

  const passwordKey: string = "password";
  const verifyPasswordKey: string = "verifyPassword";
  const passwordPolicy: string = GetPasswordPolicy();

  // toast declaration
  const noMatchToast = () => toast.error(error_message.password.no_match);
  const blankFieldToast = () => toast.error(error_message.password.blank_field);
  const errorToast = (error) => toast.error(error);
  const passwordChangedToast = () => toast.success(messages.password.success);

  const [errors, setErrors] = useState({
    password: false,
    verifyPassword: false,
    noMatch: false,
  });

  // function to verify if any of the fields contain errors
  const verifyFields = () => {
    return !Object.values(errors).some((error) => error === true);
  };

  // generic function to update error data based on key and value
  const updateError = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value,
    }));
  };

  useEffect(() => {
    const fetchUserData = async () => {
      await validateUserExists(
        setUserData,
        setLoading,
        setError,
        id, // no id as we use the token
        myUserPage, // myUserPage = true
      );
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (newPassword != confirmPassword) {
      updateError("noMatch", true);
    } else {
      updateError("noMatch", false);
    }
  }, [newPassword, confirmPassword]);

  const verifyBlankFields = () => {
    return newPassword !== "" && confirmPassword !== "";
  };

  const verifyNewPassword = () => {
    return newPassword === confirmPassword;
  };

  const verifyPasswords = (): boolean => {
    if (!verifyBlankFields()) {
      blankFieldToast();
      return false;
    }
    if (!verifyNewPassword()) {
      noMatchToast();
      return false;
    }

    if (!verifyFields()) {
      errorToast(error_message.generic.bad_field);
      return false;
    }

    return true;
  };

  const onCancel = () => {
    navigate(GENERIC_PATHS.home);
  };

  const onConfirm = async () => {
    let response;

    if (verifyPasswords()) {
      await resetUserPassword(confirmPassword, setLoading, setError)
        .then((response) => {
          if (response.status == 200) {
            passwordChangedToast();
          } else {
            const errorMessage = TranslateErrorMessage(response.message);
            console.error(errorMessage);
            setError(errorMessage);
            errorToast(errorMessage);
          }
        })
        .catch((error) => {
          const errorMessage = TranslateErrorMessage(response.message);
          console.error(errorMessage);
          setError(errorMessage);
          errorToast(errorMessage);
        });
      setLoading(false);
    }
  };

  return (
    <div>
      <TitleWithDivider titleText="パスワード変更" useDivider={true} />

      {userData ? (
        <div className="mayo-card-body rounded-xl border border-mayo-light-gray">
          <>
            <div className="mb-5">
              <PasswordForm
                identifier={"password"}
                label={"変更後のパスワード"}
                password={newPassword}
                errors={errors.password}
                setError={updateError}
                setPassword={setNewPassword}
                modal={false}
              />
            </div>

            <div className="mt-5 mb-5">
              <PasswordForm
                identifier={"verifyPassword"}
                label={"変更後のパスワード"}
                password={confirmPassword}
                errors={errors.verifyPassword}
                setError={updateError}
                setPassword={setConfirmPassword}
                modal={false}
              />
            </div>
            {errors.noMatch && (
              <p className="text-red-500">
                {UserFieldErrorMessages.passwordNoMatchError}
              </p>
            )}

            <div className="flex gap-2">
              <div className="pt-10 text-zinc-400 font-bold">
                {"パスワードポリシー: "}
              </div>
              <div className="pt-10 text-zinc-400 font-bold">{`${passwordPolicy}`}</div>
            </div>

            <ConfirmCancelButtons
              confirmText={"変更する"}
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          </>
        </div>
      ) : (
        <p className="not-found-text">{error_message.user.not_found}</p>
      )}
    </div>
  );
};

export default PasswordResetPage;
