const messages = {
  image: {
    upload_success: "イメージがアップロードされました。",
    icon_hint:
      "※きれいなアイコンにするには、正方形の画像をアップロードしてください",
  },
  csv: {
    hint: " ※ ユーザーにアプリケーションを追加する際、YOBELY または KAKELYから選択できます。APP名は英語の大文字で入力してください。",
  },
  password: {
    success: "パスワードが正常に変更されました。",
    init_success: "パスワードが初期化されました。",
    policy_information:
      "値は英字 (A-Za-z)、数字 (0-9)、アンダースコア (_) のみを含み、5文字以上64文字以内である必要があります。",
  },
  notification: {
    restart: "お知らせが再配信されました。",
    stop: "お知らせが停止されました。",
  },
  keycloak: {
    token_authed: "トークンが認証されました",
    wait_for_init:
      "Keycloakがまだ初期化されていません。しばらくお待ちください...",
  },
  user: {
    deleted: (count) => `${count}のユーザが削除されました`,
    uploaded: (createdCount, failedCount) =>
      `${createdCount}のユーザの情報がアップロードされました。${failedCount}のユーザの情報がアップロードされませんでした`,
    skipped_deletion: (userNum) =>
      `${userNum} 件のWEBユーザーアカウントが削除されました`,
  },
  generic: {
    show_error: "エラーを表示",
    error_modal_title: "エラー表示",
  },
};

export default messages;
