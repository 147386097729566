import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BusinessCardTable from "../Tables/BuisnessCardTable.tsx";
import { NewCard } from "../Cards/Templates/newCard.tsx";
import { ConfirmCancelButtons } from "../Buttons/ConfirmCancelButtons.tsx";
import {
  getContactData,
  postContact,
  putContact,
  uploadCardImage,
} from "../../services/API/Requests.tsx";
import { toast } from "react-toastify";
import {
  handleHiraganaKanaChange,
  handleNumericalCheck,
  handleRomanChange,
  phoneNumberChange,
  TranslateErrorMessage,
} from "../../services/ErrorMessages.tsx";
import { USER } from "../../types/user.ts";
import BlankUser from "../../templates/BlankUserData.tsx";
import {
  error_message,
  UserFieldErrorMessages,
} from "../../constants/Errors.tsx";
import { USER_TYPE_STRING } from "../../constants/Enums.tsx";
import { handleResponse } from "../../services/API/StatusCodes.tsx";
import WarningModal from "../Modals/WarningModal.tsx";
import messages from "../../constants/Messages.tsx";

/**
 * PhonebookProfileForm - A form component for viewing, creating, and editing contact profile information.
 *
 * This component handles the display and manipulation of contact data, including name, email, phone numbers,
 * and company details. It supports both creating new phonebook users and editing existing ones, with validation
 * for various fields and error handling. It also allows uploading business card images associated with the phonebook user.
 *
 * The component uses React hooks for managing state and side effects, and integrates with APIs for data
 * fetching, creation, and updating. It provides feedback to the user through toast notifications for success
 * or error messages.
 *
 * @returns {JSX.Element} - A JSX element representing the contact profile form with various input fields, cards, and buttons for interaction.
 *
 * Usage:
 * <PhonebookProfileForm />
 */

const PhonebookProfileForm = (): React.JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState<string>("");

  const [contactData, setContactData] = useState<USER>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const [isExternalUser, setIsExternalUser] = useState<boolean>(false);
  const [userNotFound, setUserNotFound] = useState<boolean>(false);

  const [dateCreated, setDateCreated] = useState<string>(""); // commented out awaiting instruction on implementation

  const errorToast = (response) => toast.error(`${response}`);
  const successToast = (response) => toast.success(`${response}`);

  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);

  const [createContact, setCreateContact] = useState(
    id === undefined ? true : false,
  );

  // errors for various user/contact variables
  const [errors, setErrors] = useState({
    name: false,
    first_name: false,
    last_name: false,
    kana_name: false,
    last_kana_name: false,
    first_kana_name: false,
    furigana_name: false,
    first_furigana_name: false,
    last_furigana_name: false,
    personal_tel: false,
    email: false,
    company_name: false,
    company_tel: false,
    company_fax: false,
    position_name: false,
    kana_name_length: false,
  });

  // set the user type based on if we're creating a contact (external)
  // or if it is a user already created from the contact creation
  // otherwise, if it is a user created from users page, type is internal
  useEffect(() => {
    if (contactData?.user_type == USER_TYPE_STRING.EXTERNAL || createContact) {
      setIsExternalUser(true);
    }
  }, [contactData]);

  // get our initial contact page data
  useEffect(() => {
    const fetchcontactData = async () => {
      try {
        await getContactData(setContactData, setLoading, setError, id).then(
          (response) => {
            if (response.status >= 300) {
              setUserNotFound(true);
            } else {
              setUserNotFound(false);
            }
          },
        );
      } catch (error) {
        console.error(error_message.contact.data_load, error);
        setError(error);
      }

      setLoading(false);
    };

    if (!createContact) {
      fetchcontactData();
    } else {
      setLoading(false);
      setContactData(BlankUser);
    }
  }, [id]);

  // get the user/contact's business cards
  const setBusinessCards = (newCardArray) => {
    contactData.card_image_urls = newCardArray;
  };

  // set the date for the creation variable
  useEffect(() => {
    const today: Date = new Date(Date.UTC(2012, 11, 20, 3, 0, 0));
    const stringToday: string = today.toLocaleDateString("ja-JP-u-ca-japanese");
    setDateCreated(stringToday);
  }, []);

  // handle change for the dropdown menu
  const handleDropdownChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  // when the cancel button is clicked
  const onCancel = () => {
    navigate("/KAKELY");
  };

  // when a new user creation request is submitted
  const handleCreateNewUser = (event) => {
    event.preventDefault();

    postContact(contactData, setLoading, setError)
      .then((response) => {
        if (response.status == 200) {
          if (
            contactData?.card_image_urls?.length &&
            contactData.card_image_urls.length > 0
          ) {
            handleCardUpload(response.user_id);
          }
          successToast(response.message);
          setBusinessCards([]);
        } else {
          const errorResponse = TranslateErrorMessage(response.message);
          errorToast(errorResponse);
          setError(errorResponse);
        }
      })
      .catch((error) => {
        const errorResponse = TranslateErrorMessage(error.message);
        errorToast(errorResponse);
        setError(errorResponse);
        errorToast(errorResponse);
      });
  };

  // when a user business card is uploaded
  // TODO: handle error checking
  const handleCardUpload = async (id: number) => {
    try {
      await uploadCardImage(id, contactData?.card_image_urls[0]);
    } catch (error) {
      setError(error);
      console.error(error_message.image.upload, error);
    }
  };

  // when a contact update request is submitted
  const handlePutContact = async (event) => {
    event.preventDefault();
    putContact(contactData, setLoading, setError, id)
      .then((response) => {
        if (response.status == 200) {
          successToast(response.message);
        } else {
          errorToast(response);
        }
      })
      .catch((error) => {
        setError(error);
        errorToast(error);
      });
  };

  // generic function to update user/contact data based on key and value
  const updateUserData = (key: keyof USER, value: string | boolean) => {
    setContactData((prevState) => ({
      ...prevState!,
      [key]: value,
    }));
  };

  // generic function to update error data based on key and value
  const updateError = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value,
    }));
  };

  const handleOpenWarningModal = () => {
    setWarningModalOpen(true);
  };

  const handleCloseWarningModal = () => {
    setWarningModalOpen(false);
  };

  return (
    <>
      <div>
        {userNotFound ? (
          <>
            <p className="not-found-text">
              {error_message.contact.not_found(id)}
            </p>
          </>
        ) : (
          <form
            onSubmit={createContact ? handleCreateNewUser : handlePutContact}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <div className="flex flex-row justify-end">
              {error && (
                <button
                  className="button-default"
                  type="button"
                  onClick={handleOpenWarningModal}
                >
                  {messages.generic.show_error}
                </button>
              )}
            </div>
            <NewCard
              header={"名刺画像"}
              bodyLabel={"名刺画像"}
              isLoading={loading}
              bodyContents={
                <div>
                  <div className="flex">
                    {contactData && contactData.card_image_urls.length >= 1 ? (
                      <BusinessCardTable
                        userId={contactData?.id}
                        cardData={contactData.card_image_urls}
                        setData={setBusinessCards}
                        isCreate={createContact}
                      />
                    ) : (
                      <BusinessCardTable
                        cardData={[]}
                        userId={contactData?.id}
                        setData={setBusinessCards}
                        isCreate={createContact}
                      />
                    )}
                  </div>

                  <div className="divider mt-5 mb-6"></div>

                  <h3 className="mt-10 mb-5 label-light-blue-xl">参照対象</h3>

                  <div>
                    <label className="flex items-center">
                      <select
                        className="dropdown-bar"
                        value={selectedOption}
                        onChange={handleDropdownChange}
                      >
                        <option value="newest">最新</option>
                      </select>
                    </label>
                  </div>

                  <div className="divider mt-5 mb-6"></div>

                  <div className="mt-10">
                    <h2 className="mb-5 label-light-blue-xl">
                      {" "}
                      氏名 <span className="text-red-500">*</span>{" "}
                    </h2>

                    <div className="flex flex-row">
                      <div>
                        <h2 className="mb-5 label-light-blue-md"> 姓 </h2>
                        <input
                          type="text"
                          className="form-text-field-border"
                          value={contactData?.last_name}
                          required
                          onChange={(e) =>
                            handleNumericalCheck(
                              "last_name",
                              e.target.value,
                              updateUserData,
                              updateError,
                            )
                          }
                        />
                        {errors.last_name && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.nameError}
                          </p>
                        )}
                      </div>

                      <div>
                        <h2 className="mb-5 label-light-blue-md"> 名 </h2>
                        <input
                          type="text"
                          className="form-text-field-border"
                          value={contactData?.first_name}
                          required
                          onChange={(e) =>
                            handleNumericalCheck(
                              "first_name",
                              e.target.value,
                              updateUserData,
                              updateError,
                            )
                          }
                        />
                        {errors.first_name && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.nameError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="divider mt-5 mb-6"></div>

                    <h2 className="mb-5 label-light-blue-xl"> ふりがな{""}</h2>

                    <div className="flex flex-row">
                      <div>
                        <h2 className="mb-5 label-light-blue-md"> せい </h2>
                        <input
                          type="text"
                          className="form-text-field-border"
                          value={contactData?.last_furigana_name}
                          // required
                          onChange={(e) => {
                            handleHiraganaKanaChange(
                              "last_furigana_name",
                              e.target.value,
                              updateUserData,
                              updateError,
                            );
                          }}
                        />
                        {errors.last_furigana_name && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.nameHiraganaError}
                          </p>
                        )}
                        {errors.kana_name_length && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.lengthError}
                          </p>
                        )}
                      </div>

                      <div>
                        <h2 className="mb-5 label-light-blue-md"> めい </h2>
                        <input
                          type="text"
                          className="form-text-field-border"
                          value={contactData?.first_furigana_name}
                          // required
                          onChange={(e) => {
                            handleHiraganaKanaChange(
                              "first_furigana_name",
                              e.target.value,
                              updateUserData,
                              updateError,
                            );
                          }}
                        />
                        {errors.first_furigana_name && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.nameHiraganaError}
                          </p>
                        )}
                        {errors.kana_name_length && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.lengthError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="divider mt-5 mb-6"></div>

                    {/* <h2 className="mb-5 label-light-blue-xl"> 氏名英字 </h2>

                    <div className="flex flex-row">
                      <div>
                        <h2 className="mb-5 label-light-blue-md"> 姓 </h2>
                        <input
                          type="text"
                          className="form-text-field-border"
                          value={contactData?.last_kana_name}
                          onChange={(e) =>
                            handleRomanChange(
                              "last_kana_name",
                              e.target.value,
                              updateUserData,
                              updateError,
                            )
                          }
                        />
                        {errors.last_kana_name && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.nameFuriganaError}
                          </p>
                        )}
                      </div>

                      <div>
                        <h2 className="mb-5 label-light-blue-md"> 名 </h2>
                        <input
                          type="text"
                          className="form-text-field-border"
                          value={contactData?.first_kana_name}
                          onChange={(e) =>
                            handleRomanChange(
                              "first_kana_name",
                              e.target.value,
                              updateUserData,
                              updateError,
                            )
                          }
                        />
                        {errors.first_kana_name && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.nameFuriganaError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="divider mt-5 mb-6"></div> */}

                    <h2 className="mb-5 label-light-blue-xl">
                      {" "}
                      メールアドレス
                    </h2>

                    <input
                      type="email"
                      className="form-text-field-border"
                      value={contactData?.email}
                      onChange={(e) => {
                        updateUserData("email", e.target.value);
                      }}
                    />
                    {errors.email && (
                      <p className="text-red-500">
                        {UserFieldErrorMessages.telephoneNumberError}
                      </p>
                    )}
                    <div className="divider mt-5 mb-6"></div>

                    <h2 className="mb-5 label-light-blue-xl">
                      {" "}
                      電話番号 <span className="text-red-500">*</span>{" "}
                    </h2>

                    <input
                      type="tel"
                      className="form-text-field-border"
                      value={contactData?.personal_tel}
                      required
                      onChange={(e) => {
                        phoneNumberChange(
                          "personal_tel",
                          e.target.value,
                          updateUserData,
                          updateError,
                        );
                      }}
                    />
                    {errors.personal_tel && (
                      <p className="text-red-500">
                        {UserFieldErrorMessages.telephoneNumberError}
                      </p>
                    )}
                    <div className="divider mt-5 mb-6"></div>
                  </div>
                </div>
              }
            ></NewCard>

            <NewCard
              header={`企業情報 ${!isExternalUser ? " - 内部" : " - 外部"}`}
              bodyLabel={""}
              isLoading={loading}
              bodyContents={
                <div>
                  <h2 className="mb-5 label-light-blue-xl"> 会社名 </h2>
                  <input
                    type="text"
                    className={
                      !isExternalUser
                        ? "form-text-field-border-gray"
                        : "form-text-field-border"
                    }
                    value={contactData?.company_name}
                    readOnly={!isExternalUser}
                    onChange={(e) =>
                      handleNumericalCheck(
                        "company_name",
                        e.target.value,
                        updateUserData,
                        updateError,
                      )
                    }
                  />
                  {errors.company_name && (
                    <p className="text-red-500">
                      {UserFieldErrorMessages.nameError}
                    </p>
                  )}

                  <div className="divider mt-5 mb-6"></div>

                  <h2 className="mb-5 label-light-blue-xl"> 部署・役職 </h2>
                  <input
                    type="s"
                    className={
                      !isExternalUser
                        ? "form-text-field-border-gray"
                        : "form-text-field-border"
                    }
                    value={contactData?.position_name}
                    readOnly={!isExternalUser}
                    onChange={(e) =>
                      handleNumericalCheck(
                        "position_name",
                        e.target.value,
                        updateUserData,
                        updateError,
                      )
                    }
                  />
                  {errors.position_name && (
                    <p className="text-red-500">
                      {UserFieldErrorMessages.positionError}
                    </p>
                  )}

                  <div className="divider mt-5 mb-6"></div>

                  <h2 className="mb-5 label-light-blue-xl"> 会社電話番号 </h2>
                  <input
                    type="tel"
                    className={
                      !isExternalUser
                        ? "form-text-field-border-gray"
                        : "form-text-field-border"
                    }
                    value={contactData?.company_tel}
                    readOnly={!isExternalUser}
                    onChange={(e) =>
                      phoneNumberChange(
                        "company_tel",
                        e.target.value,
                        updateUserData,
                        updateError,
                      )
                    }
                  />
                  {errors.company_tel && (
                    <p className="text-red-500">
                      {UserFieldErrorMessages.telephoneNumberError}
                    </p>
                  )}
                  <div className="divider mt-5 mb-6"></div>

                  {/* <h2 className="mb-5 label-light-blue-xl"> FAX番号 </h2> REMOVED UNTIL ADDED TO DB
                <input
                  type="tel"
                  className={!isExternalUser ? "form-text-field-border-gray" : "form-text-field-border"}
                  value={contactData?.company_fax}
                  required
                  readOnly={!isExternalUser}
                  onChange={(e) =>
                    phoneNumberChange(
                      "company_fax",
                      e.target.value,
                      updateUserData,
                      updateError,
                    )
                  }
                />
                {errors.company_fax && (
                       <p className="text-red-500">
                    {UserFieldErrorMessages.telephoneNumberError}
                  </p>
                )}

                <div className="divider mt-5 mb-6"></div> */}
                  <h2 className="mb-5 label-light-blue-xl">
                    {" "}
                    会社ホームページ{" "}
                  </h2>
                  <input
                    type="url"
                    className={
                      !isExternalUser
                        ? "form-text-field-border-gray"
                        : "form-text-field-border"
                    }
                    value={contactData?.company_home_page}
                    readOnly={!isExternalUser}
                    onChange={(e) =>
                      updateUserData("company_home_page", e.target.value)
                    }
                  />

                  <div className="divider mt-5 mb-6"></div>
                </div>
              }
            ></NewCard>

            <NewCard
              header={"登録情報"}
              bodyLabel={""}
              isLoading={loading}
              bodyContents={
                <div>
                  <h2 className="mb-5 label-light-blue-xl"> 登録日 </h2>
                  <input
                    type="text"
                    className="form-text-field-border-gray"
                    value={
                      createContact
                        ? new Date().toLocaleString("ja-JP", {
                            timeZone: "Asia/Tokyo",
                          })
                        : contactData?.created_at?.split("T")[0]
                    }
                    required
                    readOnly
                    onChange={(e) => setDateCreated(e.target.value)}
                  />

                  {/* <div className="divider mt-5 mb-6"></div>
                <h2 className="mb-5 label-light-blue-xl"> 登録者 </h2>
                <input
                  type="text"
                  className="form-text-field-border-gray"
                  value={
                    createContact
                      ? localStorage.getItem("preferred_username")
                      : contactData?.created_by
                  }
                  required
                  readOnly
                />

                <div className="divider mt-5 mb-6"></div>
                <h2 className="mb-5 label-light-blue-xl"> 登録方法 </h2>
                <input
                  type="text"
                  className="form-text-field-border-gray"
                  value={
                    createContact
                      ? "MAYO管理アプリ"
                      : contactData?.creation_method
                  }
                  required
                  readOnly
                />

                <div className="divider mt-5 mb-6"></div> */}
                </div>
              }
            ></NewCard>
            <WarningModal
              title={messages.generic.error_modal_title}
              body={error}
              confirmText={""}
              cancelText="戻る"
              isOpen={warningModalOpen}
              onRequestClose={handleCloseWarningModal}
            ></WarningModal>
            <ConfirmCancelButtons
              confirmText={"更新する"}
              form={true}
              onCancel={onCancel}
            ></ConfirmCancelButtons>
          </form>
        )}
      </div>
    </>
  );
};

export default PhonebookProfileForm;
