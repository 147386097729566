import { error_message } from "../constants/Errors";
import errorTranslations from "../constants/ErrorTranslations";
import messages from "../constants/Messages";
import { NormalizeSpaces } from "./Utilities";

export const maxStringLength = 33; // 16 for first name, 16 for last name account for space inbetween

/**
 * extractErrorMessage - Extracts error messages from a response object
 *
 * This component is provided an error message object, and extracts the error message contained inside the object.
 * Typically, this is then passed to the errorTranslations map above, and translated to Japanese.
 * If an error is not found in the map, a generic error is returned.
 *
 * @param {Object} error - The error object.
 *
 **/
const extractErrorMessage = (error) => {
  // Keycloak errors are sometimes returned as Keycloak error: {errors: an error occurred}
  // which is not correct JSON, so we treat it as a string and extract.
  if (typeof error === "string") {
    if (error.includes("User not found")) {
      return { errorMessage: error_message.user.not_found };
    }

    try {
      const jsonMatch = error.match(/{.*}/);
      if (jsonMatch) {
        const parsedError = JSON.parse(jsonMatch[0]);
        // if we find error, we need to map it to errorMessage so the rest of the code functions.
        if (parsedError.error) {
          parsedError.errorMessage = parsedError.error;
          delete parsedError.error;
        }
        if (parsedError.errorMessage) {
          return parsedError;
        }
      } else {
        return { errorMessage: error };
      }
    } catch (e) {
      console.error(error_message.generic.string_parse, e);
    }
  }

  // if we have an array of errors, we take the first one.
  if (Array.isArray(error)) {
    const errorMessage = error[0];

    try {
      const jsonMatch = errorMessage.match(/{.*}/);
      if (jsonMatch) {
        const parsedError = JSON.parse(jsonMatch[0]);
        // if we find error, we need to map it to errorMessage so the rest of the code functions.
        if (parsedError.error) {
          parsedError.errorMessage = parsedError.error;
          delete parsedError.error;
        }
        if (parsedError.errorMessage) {
          return parsedError;
        }
      } else {
        return { errorMessage: error[0] };
      }
    } catch (e) {
      console.error(error_message.generic.string_parse, e);
    }
  }

  // handle various types
  if (error?.response && error?.response.data) {
    if (typeof error.response.data === "string") {
      return { errorMessage: error.response.data };
    } else if (error.response.data.errorMessage) {
      return error.response.data;
    } else if (error.response.data.message) {
      return { errorMessage: error.response.data.message };
    }
  }

  if (error?.data?.error) {
    return { errorMessage: error.data.error };
  }

  // return the extracted message, or a generic error
  return { errorMessage: error?.message || error_message.generic.common };
};

/**
 * TranslateErrorMessage - translates an error message(s) from English to Japanese
 *
 * Using an extracted message, we find it in the map and translate it to Japanese.
 *
 * @param {Object} error - The error message.
 *
 **/
export const TranslateErrorMessage = (error) => {
  // comments kept here for easy debugging later
  // console.log("error -> ", error);
  const errorDetails = extractErrorMessage(error);
  // console.log("extracted errorDetails -> ", errorDetails);

  const { errorMessage, ...otherDetails } = errorDetails;
  const translation = errorTranslations[errorMessage];

  if (typeof translation === "function") {
    return translation(otherDetails);
  }

  const roleErrorRegex = /Couldn't find Role with 'id'=\d+/;

  if (roleErrorRegex.test(error)) {
    return error_message.role.not_found;
  }

  return translation || error_message.generic.common;
};

// Handles regex matching of kana input fields
export const handleKanaChange = (key, value, updateFunc, setError) => {
  const regex = /^[\u3040-\u309F\u30A0-\u30FF\s]*$/;

  const normalizedText = NormalizeSpaces(value);

  const lengthError = `${normalizedText}_length`;

  if (normalizedText.length > maxStringLength) {
    setError(lengthError, true);
  } else {
    setError(lengthError, false);
  }

  if (regex.test(normalizedText) || normalizedText === "") {
    setError(key, false);
  } else {
    setError(key, true);
  }

  updateFunc(key, normalizedText);
};

// Handles regex matching of kana input fields only accepting hiragana
export const handleHiraganaKanaChange = (key, value, updateFunc, setError) => {
  const regex = /^[\u3040-\u309F\sー]*$/;

  const normalizedText = NormalizeSpaces(value);

  const lengthError = `${normalizedText}_length`;

  if (normalizedText.length > maxStringLength) {
    setError(lengthError, true);
  } else {
    setError(lengthError, false);
  }

  if (regex.test(normalizedText) || normalizedText === "") {
    setError(key, false);
  } else {
    setError(key, true);
  }

  updateFunc(key, normalizedText);
};

// Handles regex matching of roman (english) input fields
export const handleRomanChange = (key, value, updateFunc, setError) => {
  const regex = /^[a-zA-Z\-\s]*$/;

  const normalizedText = NormalizeSpaces(value).trim();

  const lengthError = `${normalizedText}_length`;

  if (normalizedText.length > maxStringLength) {
    setError(lengthError, true);
  } else {
    setError(lengthError, false);
  }

  if (regex.test(normalizedText) || normalizedText === "") {
    setError(key, false);
  } else {
    setError(key, true);
  }

  updateFunc(key, normalizedText);
};

export const handleNumericalCheck = (key, value, updateFunc, setError) => {
  const regex = /[0-9]/;

  const normalizedText = NormalizeSpaces(value);

  if (regex.test(normalizedText)) {
    setError(key, true);
  } else {
    setError(key, false);
  }

  updateFunc(key, normalizedText);
};

// Handles regex matching of numeric input fields
export const handleNumericalFieldChange = (
  key,
  value,
  updateFunc,
  setError,
) => {
  const regex = /^[0-9]*$/;

  const normalizedText = NormalizeSpaces(value).trim();

  if (regex.test(normalizedText) || normalizedText === "") {
    setError(key, false);
  } else {
    setError(key, true);
  }

  updateFunc(key, normalizedText);
};

// Handles regex matching of postcode input fields
export const handlePostCodeChange = (key, value, updateFunc, setError) => {
  const regex = /^[0-9]{3}-[0-9]{4}$/;
  const normalizedText = NormalizeSpaces(value).trim();

  if (regex.test(normalizedText) || normalizedText === "") {
    setError(key, false);
  } else {
    setError(key, true);
  }
  updateFunc(key, normalizedText);
};

// Handles regex matching of phone number input fields
export const phoneNumberChange = (key, value, updateFunc, setError) => {
  const regex = /^[0-9]{10,11}$/;

  const normalizedText = NormalizeSpaces(value).trim();

  if (regex.test(normalizedText) || normalizedText === "") {
    setError(key, false);
  } else {
    setError(key, true);
  }
  updateFunc(key, normalizedText);
};

// Handles regex matching of tenant code input fields for tenant creation
export const handleCodeChange = (key, value, updateFunc, setError) => {
  const regex = /^[A-Za-z0-9_]{5,64}$/;

  const normalizedText = NormalizeSpaces(value).trim();

  if (!regex.test(normalizedText)) {
    setError(`${key}`, true, messages.password.policy_information);
  } else {
    setError(`${key}`, false);
  }

  updateFunc(key, normalizedText);
};

// Handles regex matching of passwords to a specified policy number input fields
export const handlePasswordChange = (key, value, updateFunc, setError) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

  const normalizedText = NormalizeSpaces(value).trim();

  if (regex.test(normalizedText) || normalizedText === "") {
    setError(key, false);
  } else {
    setError(key, true);
  }

  updateFunc(normalizedText);
};
