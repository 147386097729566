import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserTable from "../../components/Tables/UserTable";
import { useNavigate } from "react-router-dom";
import { USER } from "../../types/user";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import { SearchBar } from "../../components/SearchBar/SearchBarTemplate";
import { HeaderItems } from "../../common/HeaderItems";
import { getUsersData } from "../../services/API/Requests";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import LoadingIcon from "../../common/LoadingIcon";
import WarningModal from "../../components/Modals/WarningModal";
import { store } from "../../services/StateManagement/Store";
import FilterModal from "../../components/Modals/FilterSelectModal";
import PaginationControls from "../../services/PaginationControls";
import { useSelector } from "react-redux";
import { TranslateErrorMessage } from "../../services/ErrorMessages";
import { error_message } from "../../constants/Errors";
import messages from "../../constants/Messages";
import { userHeaderMapping } from "../../services/TranslateCsvHeaders";
import handleDownloadCSV from "../../services/CsvDownloadHandler";
import { USER_PATHS } from "../../constants/NavigationPaths";
import { current } from "@reduxjs/toolkit";

/**
 * UserMainPage - A component for managing and displaying a list of users.
 *
 * This component provides a user management interface where admins can:
 * - View a list of users.
 * - Search, filter, and paginate through user data.
 * - Perform bulk actions like deleting users, downloading user data as CSV, and uploading profile pictures.
 * - Navigate to pages for creating new users or uploading users via CSV.
 *
 * State:
 * - `selectedUsers` (number[]): Stores the IDs of selected users for performing bulk actions like delete or CSV download.
 * - `deleteModalOpen` (boolean): Controls the visibility of the delete confirmation modal.
 * - `filterModalOpen` (boolean): Controls the visibility of the filter modal.
 * - `searchTerm` (string): Stores the current search term for searching users.
 * - `userData` (USER[]): Stores the list of user data retrieved from the API.
 * - `loading` (boolean): Indicates whether the component is in a loading state while fetching user data.
 * - `error` (string | null): Stores error messages encountered during API interactions.
 * - `isFiltered` (boolean): Indicates whether the current user data is filtered based on a search or filter criteria.
 * - `queryString` (string): Stores the query string for filtering user data.
 * - `currentPage` (number): Stores the current page number for pagination.
 * - `totalPages` (number): Stores the total number of pages available for pagination.
 * - `perPage` (number): Stores the number of users to display per page.
 * - `filters` (object): Stores the applied filters for filtering user data.
 *
 * Functions:
 * - `handleUserSelect`: Toggles the selection of a user by their ID.
 * - `handleDeselectAll`: Clears the selection of all users.
 * - `handleDownloadCSV`: Downloads the selected user data as a CSV file.
 * - `deleteUserData`: Deletes the selected users from the system after confirmation.
 * - `onSearch`: Performs a search based on the search term.
 * - `onFilteredSearch`: Opens the filter modal for advanced filtering.
 * - `doFilteredSearch`: Applies the filters and retrieves the filtered user data.
 * - `openDeleteModal`: Opens the delete confirmation modal.
 * - `closeDeleteModal`: Closes the delete confirmation modal.
 * - `fetchUsers`: Fetches the user data based on the current page and filters.
 * - `handlePageChange`: Handles pagination by updating the current page and fetching the corresponding user data.
 *
 * API Interactions:
 * - `getUsersData`: Retrieves the list of users from the API.
 * - `getFilteredUsersData`: Retrieves the list of filtered users based on the applied filters from the API.
 * - `deleteYoberyUserData`: (Commented out) Deletes user data from an external Yobery system.
 *
 * Components:
 * - `UserTable`: A table component that displays the list of users and allows selecting users for bulk actions.
 * - `WarningModal`: A modal component for confirming deletion of selected users.
 * - `FilterModal`: A modal component for applying filters to the user data.
 * - `PaginationControls`: (Commented out) A component for handling pagination controls.
 *
 * Toast Notifications:
 * - `nonSelectedToast`: Displays an error message if no users are selected for a bulk action.
 * - `multipleUserSelectedToast`: Displays an error message if more than one user is selected for a single-user action.
 * - `userDeletedToast`: Displays a success message with the number of users deleted successfully.
 * - `errorToast`: Displays an error message with the provided message.
 *
 * Header Items:
 * The `headerItems` array defines the available actions in the header, such as:
 * - Adding a new user.
 * - Uploading user data via CSV.
 * - Downloading selected user data as CSV.
 * - Bulk uploading profile pictures.
 * - Deleting selected users.
 *
 * Usage:
 * This component is used for managing user data in an admin interface. It provides functionalities to add, edit, delete, and filter users.
 * It also includes pagination controls and bulk actions for managing multiple users efficiently.
 *
 * Example Usage:
 * <UserMainPage />
 *
 * Note:
 * - The `deleteYoberyUserData` function is currently commented out and not used. It was meant for deleting user data from an external Yobery system.
 * - The `PaginationControls` component is currently commented out and not used. Pagination is handled internally without visible controls.
 * - The component includes error handling to provide feedback if the user data is not in the expected format or if there are issues during the upload process.
 */

const UserMainPage: React.FC = () => {
  const navigate = useNavigate();

  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState("");

  const [userData, setUserData] = useState<USER[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [queryString, setQueryString] = useState<string>("");

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const lastIndex: number = currentPage * itemsPerPage;
  const firstIndex: number = lastIndex - itemsPerPage;

  const [allSelected, setAllSelected] = useState<boolean>(false);

  const totalPages: number = Math.ceil(totalUsers / itemsPerPage);

  const isAdminLoggedIn: boolean = useSelector((state) => state.auth.isAdmin);

  const nonSelectedToast = () => toast.error(error_message.user.not_selected);
  const userDeletedToast = (userNum) =>
    toast.success(messages.user.deleted(userNum));
  const errorToast = (message) => toast.error(message);

  const [filters, setFilters] = useState({
    first_name: "",
    last_name: "",
    first_furigana_name: "",
    last_furigana_name: "",
    company_name: "",
    personal_tel: "",
    email: "",
    app_name: "",
  });

  const warningModalTitle: string = "ユーザを削除注意";
  const warningModalBody: string = "ユーザを削除します。よろしいですか？";
  const warningModalConfirm: string = "削除";
  const filename: string = "名分のユーザ情報_";

  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage]);

  const handlePictureUpload = () => {
    if (selectedUsers.length === 0) {
      nonSelectedToast();
    } else {
      const users = userData
        .filter((user) => selectedUsers.includes(user.id))
        .map((user) => ({
          id: user.id,
          name: `${user.last_name} ${user.first_name}`,
        }));

      navigate(USER_PATHS.photoUpload, { state: { users } });
    }
  };

  const matchingUsers = (selectedIds: number[]): USER[] => {
    return userData.filter((user) => selectedIds.includes(user.id));
  };

  const handleUserSelect = (userId: number, force = false) => {
    setSelectedUsers((prevSelected) => {
      if (force) {
        return [...prevSelected, userId];
      } else if (prevSelected.includes(userId)) {
        setAllSelected(false);
        return prevSelected.filter((id) => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
  };

  const fetchUserInformation = async ({
    curPage = currentPage,
    itemPerPage = itemsPerPage,
    query = queryString,
  } = {}) => {
    await getUsersData(
      setUserData,
      setTotalUsers,
      setError,
      setLoading,
      curPage,
      itemPerPage,
      query,
    );
  };

  const handleDeselectAll = () => {
    setAllSelected(false);
    setSelectedUsers([]);
  };

  const handleDownloadClick = () => {
    handleDownloadCSV(
      matchingUsers(selectedUsers),
      filename,
      userHeaderMapping,
    );
  };

  useEffect(() => {
    if (!isFiltered && searchTerm == "") {
      fetchUserInformation({ query: "" });
      setCurrentPage(1);
    }
  }, [isFiltered, searchTerm]);

  const deleteYoberyUserData = async (userId) => {
    const yoberyApiPrefix: string = import.meta.env.VITE_YOBERY_API_PREFIX;
    const userAccount: string = import.meta.env.VITE_YOBERY_USER_ACCOUNT;
    const computerAccount: string = import.meta.env
      .VITE_YOBERY_COMPUTER_ACCOUNT;

    const yoberyApi = axios.create({
      baseURL: import.meta.env.VITE_SYS_API_YOBERY_URL,
    });

    const user: USER | undefined = userData.find((user) => user.id === userId);

    if (!user) {
      console.error(error_message.user.not_found);
      return;
    }

    const userInformation: { user_id: string } = { user_id: user.login_id };

    try {
      const response: AxiosResponse<any, any> = await yoberyApi.delete(
        `${yoberyApiPrefix}/users/delete`,
        {
          data: userInformation, // DELETE requires data to be passed like this
        },
      );
    } catch (error) {
      console.error(error_message.user.delete, error);
    }
  };

  const deleteUserData = async () => {
    closeDeleteModal();
    setLoading(true);
    const apiPrefix: AxiosResponse<any, any> = import.meta.env.VITE_API_PREFIX;
    let error;

    const api: AxiosInstance = axios.create({
      baseURL: import.meta.env.VITE_SYS_API_URL,
    });

    const header: { Authorization: null } = store.getState().api.headers;
    api.defaults.headers = header;

    if (selectedUsers) {
      let errorFound: boolean = false;
      const usersToDelete = selectedUsers;
      setSelectedUsers([]);
      try {
        const responses: void[] = await Promise.all(
          usersToDelete.map((user) =>
            api.delete(`${apiPrefix}/users/${user}`).then(() => {
              // deleteYoberyUserData(user); // removed for demo
            }),
          ),
        );
        // responses.forEach((response) => {
        //   console.log("user deleted successfully:", response.data);
        // });
      } catch (err) {
        errorFound = true;
        error = err?.response?.data?.errors || err?.response?.data?.error;
        setError(err.message);
        console.error(error_message.user.delete, err.message);
      }

      if (errorFound) {
        errorToast(TranslateErrorMessage(error));
      } else {
        userDeletedToast(selectedUsers.length);
      }
      setSelectedUsers([]);
      setAllSelected(false);
      fetchUserInformation();
      setCurrentPage(1);
    }

    setLoading(false);
  };

  const onSearch = () => {
    if (searchTerm) {
      const query: string = `&keyword=${searchTerm}`;
      setQueryString(query);
      setCurrentPage(1);
      fetchUserInformation({ curPage: 1 });
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
      setCurrentPage(1);
    }
  };

  const onFilteredSearch = () => {
    setFilterModalOpen(true);
  };

  const onFilteredModalClose = () => {
    setFilterModalOpen(false);
  };

  const doFilteredSearch = async (filters) => {
    const query: URLSearchParams = new URLSearchParams();

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        const searchKey = key;
        query.append(searchKey, filters[key]);
      }
    });

    const queryString = query.toString() ? `&${query.toString()}` : "";
    setQueryString(queryString);
    setCurrentPage(1);
    fetchUserInformation({ curPage: 1 });
    setIsFiltered(true);
  };

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handlePageChange = async (newPage: number) => {
    fetchUserInformation({ curPage: newPage });
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const updateUserData = async () => {
      fetchUserInformation();
      setCurrentPage(1);
    };

    updateUserData();
  }, [itemsPerPage]);

  const headerItems: {
    icon: {
      width: number;
      height: number;
      viewBox: string;
      path: string;
    };
    link: string;
    text: string;
    onClick?: () => void;
  }[] = [
    {
      icon: {
        width: 22,
        height: 22,
        viewBox: "0 0 22 22",
        path: "M11 13.5H6.5C5.10444 13.5 4.40665 13.5 3.83886 13.6722C2.56045 14.06 1.56004 15.0605 1.17224 16.3389C1 16.9067 1 17.6044 1 19M18 19V13M15 16H21M13.5 5.5C13.5 7.98528 11.4853 10 9 10C6.51472 10 4.5 7.98528 4.5 5.5C4.5 3.01472 6.51472 1 9 1C11.4853 1 13.5 3.01472 13.5 5.5Z",
      },
      link: `${USER_PATHS.createUser}`,
      text: "ユーザ新規追加",
    },
    ...(!isAdminLoggedIn
      ? [
          {
            icon: {
              width: 22,
              height: 22,
              viewBox: "0 0 22 22",
              path: "M6 19.6621C3.01099 17.9331 1 14.7013 1 10.9999C1 5.47709 5.47715 0.999939 11 0.999939C16.5228 0.999939 21 5.47709 21 10.9999C21 14.7014 18.989 17.9331 16 19.6621M15 11L11 7.00001M11 7.00001L7 11M11 7.00001V21",
            },
            link: `${USER_PATHS.csvUpload}`,
            text: "CSVアップロード",
          },
        ]
      : []),
    {
      icon: {
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        path: "M17 3.33782C19.989 5.06687 22 8.29859 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 8.29859 4.01099 5.06687 7 3.33782M8 12L12 16M12 16L16 12M12 16V2",
      },
      link: "",
      text: "CSVダウンロード",
      onClick:
        selectedUsers.length === 0 ? nonSelectedToast : handleDownloadClick,
    },
    {
      icon: {
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        path: "M16.2 21H6.93137C6.32555 21 6.02265 21 5.88238 20.8802C5.76068 20.7763 5.69609 20.6203 5.70865 20.4608C5.72312 20.2769 5.93731 20.0627 6.36569 19.6343L14.8686 11.1314C15.2646 10.7354 15.4627 10.5373 15.691 10.4632C15.8918 10.3979 16.1082 10.3979 16.309 10.4632C16.5373 10.5373 16.7354 10.7354 17.1314 11.1314L21 15V16.2M16.2 21C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2M16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2M10.5 8.5C10.5 9.60457 9.60457 10.5 8.5 10.5C7.39543 10.5 6.5 9.60457 6.5 8.5C6.5 7.39543 7.39543 6.5 8.5 6.5C9.60457 6.5 10.5 7.39543 10.5 8.5Z",
      },
      link: "",
      text: "顔写真一括アップロード",
      onClick: handlePictureUpload,
    },

    {
      icon: {
        width: 20,
        height: 22,
        viewBox: "0 0 20 22",
        path: "M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5",
      },
      link: "",
      text: "削除",
      onClick: selectedUsers.length === 0 ? nonSelectedToast : openDeleteModal,
    },
  ];

  return (
    <>
      <div>
        <TitleWithDivider titleText="ユーザ一覧" useDivider={true} />
        <SearchBar
          onSearch={onSearch}
          onFilterSearch={onFilteredSearch}
          searchBarText={"ユーザ検索キーワード"}
          buttonLeft={"検索"}
          buttonRight={"絞込検索"}
          setSearchTerm={setSearchTerm}
          disabled={loading}
        ></SearchBar>
        <HeaderItems
          items={headerItems}
          topPadding={5}
          disabled={loading}
        ></HeaderItems>
        <div className="mb-5"></div>
        {loading ? (
          <div className="flex items-center justify-center h-40 rounded-xl">
            <LoadingIcon />
          </div>
        ) : (
          <>
            <UserTable
              onUserSelect={handleUserSelect}
              onDeselectAll={handleDeselectAll}
              userInformation={userData}
              allSelected={allSelected}
              setAllSelected={setAllSelected}
            />
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              totalItems={totalUsers}
              displayedItem={"ユーザ"}
              onPageChange={handlePageChange}
              setPerPage={setItemsPerPage}
              perPage={itemsPerPage}
            />
          </>
        )}
        <WarningModal
          title={warningModalTitle}
          body={warningModalBody}
          confirmText={warningModalConfirm}
          isOpen={deleteModalOpen}
          onConfirm={deleteUserData}
          onRequestClose={closeDeleteModal}
        ></WarningModal>
        <FilterModal
          isOpen={filterModalOpen}
          onRequestClose={onFilteredModalClose}
          onApplyFilters={doFilteredSearch}
          filters={filters}
        ></FilterModal>
      </div>
    </>
  );
};

export default UserMainPage;
