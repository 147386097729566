import axios, { AxiosInstance } from "axios";
import { store } from "../StateManagement/Store";
import {
  MapAdminUser,
  MapApp,
  MapContact,
  MapContractData,
  MapNotification,
  MapRoleData,
  MapTenant,
  MapTenantDepartment,
  MapUser,
  MapUserApp,
  MapUserTenant,
} from "./MapData";
import { handleResponse } from "./StatusCodes";
import { BlankTenantDepartmentData } from "../../templates/BlankTenantDepartmentData";
import { TranslateErrorMessage } from "../../services/ErrorMessages";
import { USER_TYPE } from "../../constants/Enums";
import { useState } from "react";

// set up the api basic information on load
const apiPrefix = import.meta.env.VITE_API_PREFIX;
const yoberyApiPrefix = import.meta.env.VITE_YOBERY_API_PREFIX;

const userAccount = import.meta.env.VITE_YOBERY_USER_ACCOUNT;
const computerAccount = import.meta.env.VITE_YOBERY_COMPUTER_ACCOUNT;

const yoberyHeader = {
  Authorization: userAccount,
  Authorization2: computerAccount,
};

const api: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_SYS_API_URL,
});

const yoberyApi: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_SYS_API_YOBERY_URL,
});

//

export const apiCall = async ({
  method = "get",
  url,
  data = {},
  useToken = false,
  idReturn = false,
  onSuccess,
  onError,
  setLoading,
}) => {
  let responseMessage;
  let responseId;
  setLoading && setLoading(true);

  try {
    const header = store.getState().api.headers;
    api.defaults.headers = header;

    const response = await api({
      method,
      url: useToken ? `${apiPrefix}/users/myUser` : url,
      data,
    });

    if (idReturn) {
      responseId =
        response.data?.created_users?.[0]?.user?.id ??
        response.data?.created_users?.[0]?.id;
    }

    if (onSuccess) {
      responseMessage = onSuccess(response);
    }
  } catch (err) {
    const errorResponse =
      err.response?.data?.failed_users?.error ??
      err.response?.data?.failed_users?.[0]?.error ??
      err.response?.data?.failures?.[0]?.error ??
      err.response?.data?.errors ??
      err.response?.data?.error ??
      err.message;

    responseMessage = { status: err.status, message: errorResponse };

    if (onError) {
      onError(errorResponse);
    }
  } finally {
    setLoading && setLoading(false);

    return idReturn
      ? { ...responseMessage, user_id: responseId }
      : responseMessage;
  }
};

/**
 *  component containing all API interaction functions
 *  API main url is set in App.tsx
 *  import axios from "axios";
 *  axios.defaults.baseURL = "http://address_here:port";
 * /



/**
 * getUserData - Fetches and sets the user data including associated tenant department, user roles, apps, and skill tags.
 * 
 * This function makes an API call to retrieve detailed user information and associated data based on the provided userId.
 * The retrieved data includes user details, tenant department information, user roles, assigned applications, and skill tags.
 * This data is then processed and set to the respective state variables using the provided setter functions.
 * 
 * @async
 * @function getUserData
 * @param {Function} setUser - Setter function to update the state with the user data.
 * @param {Function} setUserTenant - Setter function to update the state with the user tenant department data.
 * @param {Function} setTenantDepartment - Setter function to update the state with the tenant department data.
 * @param {Function} setUserRole - Setter function to update the state with the user role data.
 * @param {Function} setBroadcastGroup - (Unused currently) Setter function to update the state with the broadcast group data.
 * @param {Function} setUserApps - Setter function to update the state with the user's assigned applications.
 * @param {Function} setUserSkillTags - Setter function to update the state with the user's skill tags.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {string} userId - The unique identifier of the user to fetch the data for.
 * @param {boolean} [useToken=false] - Flag to determine whether to use the logged-in user's token to fetch data (default: false).
 * @returns {Promise<{status: number, message: string}>} - Returns a JSON object containing the status and message of the API response.
 */

export const getUserData = async (
  setUser,
  setUserTenant,
  setTenantDepartment,
  setUserRole,
  setUserApps,
  setUserSkillTags,
  setLoading,
  setError,
  userId,
  useToken = false,
) => {
  return apiCall({
    method: "get",
    url: `${apiPrefix}/users/${userId}`,
    useToken,
    setLoading,
    onSuccess: (response) => {
      const userAppData = response.data.available_apps.map((app) => ({
        app_id: app.app_id,
      }));

      setUser(MapUser(response.data));
      setUserApps(userAppData);

      setUserTenant(
        response.data.internal_departments[0]
          ? MapUserTenant(response.data?.internal_departments[0])
          : BlankTenantDepartmentData,
      );

      setUserRole(response.data ? MapRoleData(response.data) : []);

      // if (tenant_department) {
      //   setTenantDepartment(MapTenantDepartment(tenant_department));
      // }
    },
    onError: (errorResponse) => {
      setError(errorResponse);
    },
  });
};

/**
 * validateUserExists - Validates if a user exists by fetching and setting the user data.
 *
 * This function makes an API call to check if a user exists based on the provided `userId`.
 * If the user is found, the user data is processed and set to the respective state variable using the provided `setUser` function.
 * It only returns the user data and discards all other response data.
 *
 * @async
 * @function validateUserExists
 * @param {Function} setUser - Setter function to update the state with the validated user data.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {string} userId - The unique identifier of the user to validate.
 * @param {boolean} [useToken=false] - Flag to determine whether to use the logged-in user's token to fetch data (default: false).
 * @returns {Promise<{status: number, message: string}>} - Returns a JSON object containing the status and message of the API response.
 */

export const validateUserExists = async (
  setUser,
  setLoading,
  setError,
  userId,
  useToken = false,
) => {
  return apiCall({
    method: "get",
    url: `${apiPrefix}/users/${userId}`,
    useToken,
    setLoading,
    onSuccess: (response) => {
      const userData = response.data;
      setUser(MapUser(userData));
    },
    onError: (errorResponse) => {
      setError(errorResponse);
    },
  });
};

export const validateTenantExists = async (
  tenantCode,
  setLoading,
  setError,
  useToken = false,
) => {
  return apiCall({
    method: "get",
    url: `${apiPrefix}/tenants/${tenantCode}`,
    useToken,
    setLoading,
    onSuccess: (response) => {
      return true;
    },
    onError: (errorResponse) => {
      setError(errorResponse);
      return false;
    },
  });
};

/**
 * getUsersData - Fetches and sets multiple users' information from the API.
 *
 * This function makes an API call to retrieve a list of users with pagination support.
 * The retrieved user data is mapped to a simplified format and set to the state using the provided `setUserData` function.
 *
 * @async
 * @function getUsersData
 * @param {Function} setUserData - Setter function to update the state with the fetched user data array.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @returns {Promise<{status: number, message: string}>} - Returns a JSON object containing the status and message of the API response.
 */

export const getUsersData = async (
  setUserData,
  setTotalUsers,
  setError,
  setLoading,
  currentPage,
  itemsPerPage,
  query,
  type = 1,
) => {
  const queryParam = query || "";

  const url =
    type != 0
      ? `${apiPrefix}/users/?user_type=${type}&next_page=${currentPage}&per_page=${itemsPerPage}${queryParam}`
      : `${apiPrefix}/users/?next_page=${currentPage}&per_page=${itemsPerPage}${queryParam}`;

  if (currentPage <= 0) {
    currentPage = 1;
  }

  return apiCall({
    method: "get",
    url: url,
    setLoading,
    onSuccess: (response) => {
      const users = response.data.users.map(MapUser);

      const curPage =
        response.data.next_page - 1 >= 1 ? response.data.next_page - 1 : 1;

      setUserData(users);
      setTotalUsers(response.data.total_count);
    },
    onError: (errorResponse) => {
      setError(errorResponse);
    },
  });
};

/**
 * getTenantData - Fetches and sets the data of a single tenant from the API.
 *
 * This function makes an API call to retrieve detailed information about a specific tenant identified by the provided `tenantId`.
 * The retrieved tenant data is processed and set to the state using the provided `setTenant` function.
 *
 * @async
 * @function getTenantData
 * @param {Function} setTenant - Setter function to update the state with the fetched tenant data.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {string} tenantId - The unique identifier of the tenant to fetch the data for.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getTenantData = async (
  setTenant,
  setLoading,
  setError,
  tenantId,
) => {
  return apiCall({
    method: "get",
    url: `v1/tenants/${tenantId}`,
    setLoading,
    onSuccess: (response) => {
      setTenant(MapTenant(response.data));
    },
    onError: (errorResponse) => {
      setError(errorResponse);
    },
  });
};

export const getTenantsData = async (
  setTenantsData,
  setTotalTenants,
  setLoading,
  setError,
  currentPage,
  itemsPerPage,
  query,
) => {
  const queryParam = query || "";
  return apiCall({
    method: "get",
    url: `v1/tenants/?current_page=${currentPage}&per_page=${itemsPerPage}${queryParam}`,
    setLoading,
    onSuccess: (response) => {
      const tenants = response.data.tenants.map(MapTenant);
      setTotalTenants(response.data.total_count);
      setTenantsData(tenants);
    },
    onError: (errorResponse) => {
      setError(errorResponse);
    },
  });
};

export const getFilteredTenantsData = async (
  setTenantsData,
  setLoading,
  setError,
  query,
) => {
  return apiCall({
    method: "get",
    url: query ? `${apiPrefix}/tenants/?${query}` : `${apiPrefix}/tenants/`,
    setLoading,
    onSuccess: (response) => {
      const tenants = response.data.map(MapTenant);
      setTenantsData(tenants);
    },
    onError: (errorResponse) => {
      setError(errorResponse);
    },
  });
};

/**
 * getAdminUserData - Fetches and sets the data of a specific admin user associated with a tenant from the API.
 *
 * This function makes an API call to retrieve information about an admin user based on the provided `tenantId`.
 * The retrieved admin user data is processed and set to the state using the provided `setAdminUser` function.
 *
 * @async
 * @function getAdminUserData
 * @param {Function} setAdminUser - Setter function to update the state with the fetched admin user data.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {string} tenantId - The unique identifier of the tenant for which to fetch the associated admin user data.
 * @returns {Promise<{status: number, message: string, data?: object}>} -
 * Returns a JSON object containing the status, message, and data of the API response.
 */

export const getAdminUserData = async (
  setAdminUser,
  setLoading,
  setError,
  tenantId,
) => {
  return apiCall({
    method: "get",
    url: `${apiPrefix}/admin_users/${tenantId}`,
    setLoading,
    onSuccess: (response) => {
      setAdminUser(MapAdminUser(response));
    },
    onError: (errorResponse) => {
      setError(errorResponse);
    },
  });
};

/**
 * getLicenseData - Fetches and sets the data of a single license based on the provided license name.
 *
 * This function makes an API call to retrieve detailed information about a specific license identified by the provided `licenseName`.
 * The retrieved license data is processed and set to the state using the provided `setLicense` function.
 *
 * @async
 * @function getContractData
 * @param {Function} setLicense - Setter function to update the state with the fetched license data.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {string} licenseName - The unique name of the license to fetch the data for.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getLicenseData = async (
  setLicense,
  setError,
  setLoading,
  licenseName,
) => {
  return apiCall({
    method: "get",
    url: `${apiPrefix}/licenses/${licenseName}`,
    setLoading,
    onSuccess: (response) => {
      setLicense(MapContractData(response));
    },
    onError: (errorResponse) => {
      setError(errorResponse);
    },
  });
};

/**
 * getCompanyContractData - Fetches and sets the data of all licenses associated with a company.
 *
 * This function makes an API call to retrieve a list of all contracts associated with the company.
 * The retrieved license data is mapped to a simplified format and set to the state using the provided `setLicenseData` function.
 *
 * @async
 * @function getCompanyContractData
 * @param {Function} setContractData - Setter function to update the state with the fetched license data array.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getCompanyContractData = async (
  setContractData,
  setLoading,
  setError,
  id,
) => {
  return apiCall({
    method: "get",
    url: `${apiPrefix}/contracts/all/${id}`,
    setLoading,
    onSuccess: (response) => {
      const licenseData = response.data.map(MapContractData);
      setContractData(licenseData);
    },
    onError: (errorResponse) => {
      setError(errorResponse);
    },
  });
};

/**
 * postContractData - posts licenses associated with a company.
 *
 *
 * @async
 * @function postContractData
 * @param {Function} contractData -
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const postContractData = async (contractData, setLoading, setError) => {
  const contracts = contractData.map(MapContractData);

  return apiCall({
    method: "post",
    url: `${apiPrefix}/contracts/`,
    data: { contracts },
    setLoading,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "契約");
      return responseMessage;
    },
    onError: (errorResponse) => {
      setError(errorResponse);
    },
  });
};

/**
 * putContractData - puts licenses associated with a company.
 *
 *
 * @async
 * @function postContractData
 * @param {Function} contractData -
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const putContractData = async (
  contractData,
  setLoading,
  setError,
  id,
) => {
  const contracts = contractData.map(MapContractData);

  return apiCall({
    method: "put",
    url: `${apiPrefix}/contracts/${id}`,
    data: { contracts },
    setLoading,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "契約");
      return responseMessage;
    },
    onError: (errorResponse) => {
      setError(errorResponse);
    },
  });
};

/**
 * postUserData - Sends a new user's information to the API for creation.
 *
 * This function makes an API call to create a new user using the provided user data along with associated tenant, role,
 * and application information. It constructs a request payload from the provided data and posts it to the API.
 *
 * @async
 * @function postUserData
 * @param {Object} userData - The user data to be sent to the API. Excludes id and uid.
 * @param {Object} userTenantData - The tenant department data associated with the user.
 * @param {Object} userBroadcastGroupData - (Optional) The broadcast group data associated with the user.
 * @param {Object} roleData - The role data associated with the user.
 * @param {Array<Object>} appData - The list of applications associated with the user.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string, user_id?: string}>} -
 * Returns a JSON object containing the status, message, and created user ID (if successful).
 */

export const postUserData = async (
  userData,
  userTenantData = {},
  userBroadcastGroupData = {},
  roleData = {},
  appData = [],
  setLoading,
  setError,
) => {
  if (!userData) return;

  const { id, uid, name, furigana_name, ...filteredUserData } = userData;
  filteredUserData.user_type = USER_TYPE.INTERNAL; // Set internal user

  const apps = appData.map((app) => ({
    id: app.id,
    name: app.name,
  }));

  const requestData = {
    user: {
      ...filteredUserData,
      user_tenant_id: userTenantData?.tenant_department_id || 0,
      role_id: roleData?.id || null,
      position_name: userTenantData?.position_name || "",
      apps,
    },
  };

  return apiCall({
    method: "post",
    url: `${apiPrefix}/users`,
    data: requestData,
    idReturn: true,
    setLoading,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "ユーザ");

      return {
        ...responseMessage,
        user_id: response.data?.create_users?.[0]?.user?.id,
      };
    },
    onError: (errorResponse) => {
      const error =
        errorResponse?.failed_users?.[0]?.error ||
        errorResponse.errors ||
        errorResponse.error ||
        errorResponse;
      setError(error);
      console.log("error -> ", error);
    },
  });
};

export const postUserDataArray = async (userData, setLoading, setError) => {
  if (userData) {
    let response;
    let responseMessage;
    let failedUsers;
    let createdUsers;
    let request_id;

    const userDataArray = userData.map((user) => {
      const { id, uid, name, furigana_name, ...filteredUserData } =
        user.userInfo;

      filteredUserData.user_type = USER_TYPE.INTERNAL;

      const apps = user.userApps.map((app) => ({
        id: app.id,
        name: app.name,
      }));

      return {
        ...filteredUserData,
        user_tenant_id: user.userTenantData?.tenant_department_id || 0,
        role_id: user.roleData?.id || null,
        position_name: user?.userInfo?.position_name || "",
        apps,
      };
    });

    try {
      const header = store.getState().api.headers;
      api.defaults.headers = header;

      setLoading(true);

      response = await api.post(`${apiPrefix}/users`, {
        user: userDataArray,
      });
      responseMessage = handleResponse(response, "ユーザ");
      request_id = response.data.request_id;
    } catch (err) {
      failedUsers = err.response.data.failed_users;

      console.error(err.message);
      createdUsers =
        err.response?.data?.created_users?.filter(
          (user) => user?.login_id !== null,
        ) || [];
      failedUsers =
        err.response?.data?.failed_users?.filter(
          (user) => user?.login_id !== null,
        ) || [];
      const errorResponse =
        err?.response?.data?.errors ||
        err?.response?.data?.error ||
        err?.message;

      responseMessage = { status: err.status || 422, message: errorResponse };
      setError(errorResponse);
    } finally {
      setLoading(false);
      if (response) {
        failedUsers =
          response?.data?.failed_users?.filter(
            (user) => user?.login_id !== null,
          ) || [];
        createdUsers = response.data?.created_users;
        responseMessage = {
          ...responseMessage,
          // user_id: response.data.user.id, YOBELY integration
        };
      }

      // disabled for demo
      // if (response.status == 201) {
      //   const yoberyRequestData = {
      //     user_id: userData.login_id,
      //     password: userData.login_password,
      //     broadcast_group_id: userBroadcastGroupData?.broadcast_group_id,
      //     first_name: userData.name.split("　")[0],
      //     first_name_furigana: userData.kana_name.split("　")[0],
      //     last_name: userData.name.split("　")[1],
      //     last_name_furigana: userData.kana_name.split("　")[1],
      //   };

      //   try{

      //     await postYoberyUserData(
      //       yoberyRequestData,
      //       setLoading,
      //       setError,
      //     );
      //   }catch{

      //   }
      // }

      if (failedUsers.length) {
        failedUsers?.forEach((user) => {
          const translatedMessage = TranslateErrorMessage(user.error);
          user.error = translatedMessage;
        });
      } else {
        const translatedMessage = TranslateErrorMessage(failedUsers.error);
        failedUsers.error = translatedMessage;
      }

      return {
        responseMessage,
        request_id: request_id,
        failed_users: failedUsers,
        created_users: createdUsers,
      };
    }
  }
};

/**
 * postUserTenantData - Sends user-tenant department data to the API for creation.
 *
 * This function makes an API call to create a new user-tenant department association using the provided user-tenant data.
 *
 * @async
 * @function postUserTenantData
 * @param {Object} userTenantData - The user-tenant department data to be sent to the API.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const postUserTenantData = async (
  userTenantData,
  setLoading,
  setError,
) => {
  if (!userTenantData) return;

  return apiCall({
    method: "post",
    url: `${apiPrefix}/user_tenant_departments/`,
    data: { user_tenant: userTenantData },
    setLoading,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "ユーザテナント");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * putUserTenantData - Updates user-tenant department data in the API.
 *
 * This function makes an API call to update an existing user-tenant department association using the provided user-tenant data.
 * It can either use the current logged-in user's token to identify the user or use the `user_id` from the `userTenantData`.
 *
 * @async
 * @function putUserTenantData
 * @param {Object} userTenantData - The updated user-tenant department data to be sent to the API.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {boolean} [useToken=false] - Flag to determine whether to use the logged-in user's token for the update (default: false).
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const putUserTenantData = async (
  userTenantData,
  setLoading,
  setError,
  useToken = false,
) => {
  if (!userTenantData) return;

  const url = useToken
    ? `${apiPrefix}/user_tenant_departments/myUser`
    : `${apiPrefix}/user_tenant_departments/${userTenantData.user_id}`;

  return apiCall({
    method: "put",
    url,
    data: userTenantData,
    setLoading,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "ユーザテナント");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};
/**
 * postTenantData - Sends new tenant data to the API for creation.
 *
 * This function makes an API call to create a new tenant using the provided tenant data.
 *
 * @async
 * @function postTenantData
 * @param {Object} tenantData - The tenant data to be sent to the API.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const postTenantData = async (tenantData, setLoading, setError) => {
  if (!tenantData) return;

  return apiCall({
    method: "post",
    url: `${apiPrefix}/tenants/`,
    data: tenantData,
    setLoading,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "テナント");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};
/**
 * postTenantAdminData - Sends new tenant and admin user data to the API for creating both a tenant and its associated admin user.
 *
 * This function makes an API call to create a new tenant and an admin user simultaneously.
 * It constructs a request payload with the tenant and admin user data and posts it to the API endpoint.
 *
 * @async
 * @function postTenantAdminData
 * @param {Object} tenantData - The tenant data to be sent to the API.
 * @param {Object} adminUserData - The admin user data to be sent to the API.
 * @param {Object} contractData - The contract data to be sent to the API.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const postTenantAdminData = async (
  tenantData,
  adminUserData,
  contractData,
  setLoading,
  setError,
) => {
  if (!tenantData || !adminUserData) return;

  const { id, uid, ...filteredTenantData } = tenantData;

  // const startDate = new Date();
  // adminUserData.start_date = startDate.toISOString().split("T")[0];

  // const endDate = new Date();
  // endDate.setFullYear(endDate.getFullYear() + 1);
  // adminUserData.end_date = endDate.toISOString().split("T")[0];

  const contracts = contractData.map(MapContractData);

  return apiCall({
    method: "post",
    url: `${apiPrefix}/tenants/create_tenant_user`,
    data: {
      tenant: filteredTenantData,
      admin_user: adminUserData,
      contracts: contracts,
    },
    setLoading,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "テナント管理者");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getUserTenantData - Fetches and sets the user-tenant department data from the API.
 *
 * This function makes an API call to retrieve the user-tenant department information based on the provided `userData` or the current logged-in user.
 * The retrieved data is then processed and set to the state using the provided `setUserTenant` function.
 *
 * @async
 * @function getUserTenantData
 * @param {Function} setUserTenant - Setter function to update the state with the fetched user-tenant department data.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {Object} userData - The user data object containing user information such as `id`.
 * @param {boolean} [useToken=false] - Flag to determine whether to use the logged-in user's token for the request (default: false).
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getUserTenantData = async (
  setUserTenant,
  setLoading,
  setError,
  userData,
  useToken = false,
) => {
  if (!userData && !useToken) return;

  const url = useToken
    ? `v1/user_tenant_departments/myUser`
    : `v1/user_tenant_departments/${userData.id}`;

  return apiCall({
    method: "get",
    url,
    setLoading,
    onSuccess: (response) => {
      setUserTenant(MapUserTenant(response));
      const responseMessage = handleResponse(response, "ユーザテナント");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getUserAppData - Fetches and sets the application data for a single user from the API.
 *
 * This function makes an API call to retrieve the list of applications associated with a specific user identified by `userId`.
 * The retrieved user application data is mapped to a simplified format and set to the state using the provided `setUserAppData` function.
 *
 * @async
 * @function getUserAppData
 * @param {Function} setUserAppData - Setter function to update the state with the fetched user application data array.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {string} userId - The unique identifier of the user to fetch the application data for.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getUserAppData = async (
  setUserAppData,
  setLoading,
  setError,
  userId,
) => {
  if (!userId) return;

  return apiCall({
    method: "get",
    url: `v1/user_apps/${userId}`,
    setLoading,
    onSuccess: (response) => {
      const userApps = response.data.map(MapUserApp);
      setUserAppData(userApps);
      const responseMessage = handleResponse(response, "ユーザアップ");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getAppData - Fetches and sets the application data from the API.
 *
 * This function makes an API call to retrieve a list of all available applications.
 * The retrieved application data is mapped to a simplified format and set to the state using the provided `setAppData` function.
 *
 * @async
 * @function getAppData
 * @param {Function} setAppData - Setter function to update the state with the fetched application data array.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getAppData = async (setAppData, setLoading, setError) => {
  return apiCall({
    method: "get",
    url: `v1/apps/`,
    setLoading,
    onSuccess: (response) => {
      const apps = response.data.map(MapApp);
      setAppData(apps);
      const responseMessage = handleResponse(response, "ユーザアップ");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getUsersAppData - Fetches and sets the user-application data from the API.
 *
 * This function makes an API call to retrieve a list of all user-application associations.
 * The retrieved user-application data is mapped to a simplified format and set to the state using the provided `setUserAppData` function.
 *
 * @async
 * @function getUsersAppData
 * @param {Function} setUserAppData - Setter function to update the state with the fetched user-application data array.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getUsersAppData = async (setUserAppData, setLoading, setError) => {
  return apiCall({
    method: "get",
    url: `v1/user_apps/`,
    setLoading,
    onSuccess: (response) => {
      const userApps = response.data.map(MapUserApp);

      setUserAppData(userApps);
      const responseMessage = handleResponse(response, "ユーザアップ");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * postContact - Sends a new contact's information to the API for creation.
 *
 * This function makes an API call to create a new contact using the provided contact data.
 * The contact data is filtered to exclude `id` and `uid` fields before being sent to the API.
 *
 * @async
 * @function postContact
 * @param {Object} contactData - The contact data to be sent to the API.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string, contact_id?: string}>} -
 * Returns a JSON object containing the status, message, and the created contact ID (if successful).
 */

export const postContact = async (contactData, setLoading, setError) => {
  if (!contactData) return;

  const { id, uid, name, furigana_name, ...filteredData } = contactData;

  filteredData.user_type = USER_TYPE.EXTERNAL; // Set to 2 for external user

  const dataToSend = {
    contact: {
      ...filteredData,
    },
  };

  return apiCall({
    method: "post",
    url: `${apiPrefix}/users/create_contact`,
    data: dataToSend,
    setLoading,
    idReturn: true,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "電話帳");
      return {
        ...responseMessage,
        user_id: response.data?.create_users?.[0]?.user?.id,
      };
    },
    onError: (errorResponse) => {
      const error =
        errorResponse?.failed_users?.[0]?.error ||
        errorResponse.errors ||
        errorResponse.error ||
        errorResponse;
      setError(error);
    },
  });
};

export const postContactArray = async (contactData, setLoading, setError) => {
  if (contactData) {
    let response;
    let responseMessage;
    let failedUsers;
    let createdUsers;
    let request_id;

    const userDataArray = contactData.map((user) => {
      const { id, uid, name, furigana_name, ...filteredUserData } =
        user.userInfo;
      filteredUserData.user_type = USER_TYPE.EXTERNAL; // set external user

      return {
        ...filteredUserData,
      };
    });

    try {
      const header = store.getState().api.headers;
      api.defaults.headers = header;

      setLoading(true);
      response = await api.post(`${apiPrefix}/users/create_contact`, {
        contact: userDataArray,
      });
      responseMessage = handleResponse(response, "電話帳");
      createdUsers = response?.data?.created_users;
      failedUsers = response?.data?.failed_users;
      request_id = response?.data?.request_id;
    } catch (err) {
      console.error(err.message);
      failedUsers = err.response.data.failed_users;
      const errorResponse =
        err.response.data.errors || err.response.data.error || err.message;
      responseMessage = { status: err.status, message: errorResponse };
      setError(errorResponse);
    } finally {
      setLoading(false);

      if (failedUsers != null) {
        if (failedUsers.length > 1) {
          failedUsers.forEach((user) => {
            const translatedMessage = TranslateErrorMessage(user.error[0]);
            user.error = translatedMessage;
          });
        } else {
          const translatedMessage = TranslateErrorMessage(failedUsers.error[0]);
          failedUsers.error = translatedMessage;
        }
      }
      return {
        responseMessage,
        failed_users: failedUsers,
        created_users: createdUsers,
        request_id: request_id,
      };
    }
  }
};

/**
 * putContact - Updates an existing contact's information in the API.
 *
 * This function makes an API call to update an existing contact identified by the provided `contact_id` using the supplied contact data.
 * The contact data is filtered to exclude `id` and `uid` fields before being sent to the API.
 *
 * @async
 * @function putContact
 * @param {Object} contactData - The updated contact data to be sent to the API.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {string} contact_id - The unique identifier of the contact to be updated.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const putContact = async (
  contactData,
  setLoading,
  setError,
  contact_id,
) => {
  if (!contactData) return;

  const { id, uid, name, furigana_name, ...filteredData } = contactData;

  const firstName = name ? name.split(" ")[1] : "";
  const lastName = name ? name.split(" ")[0] : "";

  const first_furigana_name = furigana_name ? furigana_name.split(" ")[1] : "";
  const last_furigana_name = furigana_name ? furigana_name.split(" ")[0] : "";

  const dataToSend = {
    user: filteredData,
    first_name: firstName,
    last_name: lastName,
    first_furigana_name,
    last_furigana_name,
  };

  return apiCall({
    method: "put",
    url: `${apiPrefix}/users/${contact_id}`,
    data: dataToSend,
    setLoading,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "電話帳");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * deleteContactData - Deletes multiple contacts from the API based on their IDs.
 *
 * This function makes multiple API calls to delete a list of contacts identified by their IDs.
 * If any of the delete operations fail, it captures the error message and returns it.
 *
 * @async
 * @function deleteContactData
 * @param {number[]} selectedUsers - An array of contact IDs to be deleted.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API calls.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const deleteContactData = async (
  selectedUsers: number[],
  setLoading: (loading: boolean) => void,
  setError: (error: string | null) => void,
) => {
  let response;
  let responseMessage;
  setLoading(true);
  try {
    const header = store.getState().api.headers;
    api.defaults.headers = header;

    response = await Promise.all(
      selectedUsers.map((contact) =>
        api.delete(`/v1/contacts/${contact}`).catch((err) => err.response),
      ),
    );

    const errorResponse = response.find((res) => res && res.status >= 400);

    if (errorResponse) {
      setError(errorResponse.data?.message);
      return errorResponse;
    } else {
      setError(null);
    }
  } catch (err) {
    console.error(err.message);
    const errorResponse =
      err.response.data.errors || err.response.data.error || err.message;
    responseMessage = { status: err.status, message: errorResponse };
    setError(errorResponse);
  } finally {
    response = {
      status: 200,
    };
    responseMessage = handleResponse(response, "電話帳");
    setLoading(false);
    return responseMessage;
  }
};

/**
 * putUserData - Updates a single user's information in the API.
 *
 * This function makes an API call to update an existing user's information, including tenant, role, and application associations.
 * It constructs a request payload from the provided data and updates the user in the API.
 * If the update is successful, additional data related to a broadcast group may be sent to another API.
 *
 * @async
 * @function putUserData
 * @param {Object} userData - The user data to be updated. Contains basic user information.
 * @param {Object} userTenantData - The tenant department data associated with the user.
 * @param {Object} userBroadcastGroupData - (Optional) The broadcast group data associated with the user.
 * @param {Object} roleData - The role data associated with the user.
 * @param {Array<Object>} appData - The list of applications associated with the user.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const putUserData = async (
  userData,
  userTenantData,
  userBroadcastGroupData,
  roleData,
  appData,
  setLoading,
  setError,
) => {
  if (!userData) return;

  const userId = userData.id;

  const apps = appData.map((app) => ({
    id: app.id,
    name: app.name,
  }));

  const {
    id,
    uid,
    name,
    furigana_name,
    internal_departments,
    ...filteredUserData
  } = userData;

  const requestData = {
    user: {
      ...filteredUserData,
      ...(userTenantData && {
        user_tenant_id: userTenantData.tenant_department_id,
        position_name: userTenantData.position_name,
      }),
      role_id: roleData.id,
      apps,
    },
  };

  return apiCall({
    method: "put",
    url: `${apiPrefix}/users/${userId}`,
    data: requestData,
    setLoading,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "ユーザ");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};
/**
 * putTenantData - Updates a single tenant's information in the API.
 *
 * This function makes an API call to update an existing tenant's information using the provided `tenantData`.
 * The tenant data is sent to the API endpoint using a PUT request to modify the tenant record.
 *
 * @async
 * @function putTenantData
 * @param {Object} tenantData - The tenant data to be updated. Contains tenant information such as ID and other details.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const putTenantData = async (tenantData, setLoading, setError) => {
  if (!tenantData) return;

  return apiCall({
    method: "put",
    url: `${apiPrefix}/tenants/${tenantData.id}`,
    data: tenantData,
    setLoading,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "テナント");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getTenantDepartmentData - Fetches and sets a single tenant department's data from the API.
 *
 * This function makes an API call to retrieve detailed information about a specific tenant department identified by the provided `tenantDepartmentId`.
 * The retrieved data is processed and set to the state using the provided `setTenantDepartment` function.
 *
 * @async
 * @function getTenantDepartmentData
 * @param {Function} setTenantDepartment - Setter function to update the state with the fetched tenant department data.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {string} tenantDepartmentId - The unique identifier of the tenant department to fetch the data for.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getTenantDepartmentData = async (
  setTenantDepartment,
  setLoading,
  setError,
  tenantDepartmentId,
) => {
  if (!tenantDepartmentId) return;

  return apiCall({
    method: "get",
    url: `${apiPrefix}/tenant_departments/${tenantDepartmentId}`,
    setLoading,
    onSuccess: (response) => {
      const tenantDepartment = MapTenantDepartment(response.data);

      setTenantDepartment(tenantDepartment);
      const responseMessage = handleResponse(response, "部署");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getTenantDepartmentsData - Fetches and sets the data of all tenant departments from the API.
 *
 * This function makes an API call to retrieve a list of all tenant departments. The retrieved data is mapped to a simplified format
 * and set to the state using the provided `setTenantDepartment` and `setOriginalTenantDepartments` functions.
 *
 * @async
 * @function getTenantDepartmentsData
 * @param {Function} setTenantDepartment - Setter function to update the state with the fetched tenant departments data array.
 * @param {Function} setOriginalTenantDepartments - Setter function to store the original tenant departments data before any alteration.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getTenantDepartmentsData = async (
  setTenantDepartment,
  setOriginalTenantDepartments,
  setLoading,
  setError,
) => {
  return apiCall({
    method: "get",
    url: `v1/tenant_departments`,
    setLoading,
    onSuccess: (response) => {
      const departments = response.data.map(MapTenantDepartment);

      setTenantDepartment(departments);
      setOriginalTenantDepartments(departments);
      const responseMessage = handleResponse(response, "部署");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};
/**
 * putTenantDepartmentsData - Updates a single tenant department's data in the API.
 *
 * This function makes an API call to update an existing tenant department's information using the provided `tenantDepartmentData`.
 * The tenant department data is sent to the API endpoint using a PUT request to modify the department record.
 *
 * @async
 * @function putTenantDepartmentsData
 * @param {Object} tenantDepartmentData - The tenant department data to be updated. Contains tenant department information such as ID and other details.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const putTenantDepartmentsData = async (
  tenantDepartmentData,
  setLoading,
  setError,
) => {
  if (!tenantDepartmentData) return;

  return apiCall({
    method: "put",
    url: `${apiPrefix}/tenant_departments/${tenantDepartmentData.id}`,
    data: tenantDepartmentData,
    setLoading,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "部署");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * postTenantDepartmentData - post a single tenantDepartments data
 *
 * @component
 * @param {Function} setError - setter function to set the error value
 * @param {Function} setLoading - setting function to set the value of loading
 * @param {Function} setTenantDepartment - sets the tenant department data
 * @param {TENANTDEPARTMENT} tenantDepartmentData - data from the tenant department to post
 * @returns {JSON} - returns a json file with {status: , message:}
 */

// this functions slightly different to the others - we update this as soon as we alter it so the user can see the change.
export const postTenantDepartmentData = async (
  setTenantDepartmentData,
  tenantDepartmentData,
  setLoading,
  setError,
) => {
  if (!tenantDepartmentData) return;

  const { id, uid, ...filteredData } = tenantDepartmentData;

  return apiCall({
    method: "post",
    url: `${apiPrefix}/tenant_departments/`,
    data: filteredData,
    setLoading,
    onSuccess: (response) => {
      const tenantDepartment = {
        id: response.data.id,
        tenant_id: response.data.tenant_id,
        name: response.data.name,
        parent: response.data.parent ? response.data.parent.id : null,
        name_kana: response.data.name_kana,
        code: response.data.code,
        display_order: response.data.display_order,
        department_level: response.data.department_level,
      };

      setTenantDepartmentData(tenantDepartment);
      const responseMessage = handleResponse(response, "部署");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * postTenantDepartmentData - Creates a new tenant department in the API and updates the state immediately.
 *
 * This function makes an API call to create a new tenant department using the provided `tenantDepartmentData`.
 * The data is filtered to exclude `id` and `uid` fields before being sent to the API. Once the new department is created,
 * the state is updated immediately with the newly created department data to reflect the changes in the UI.
 *
 * @async
 * @function postTenantDepartmentData
 * @param {Function} setTenantDepartmentData - Setter function to update the state with the newly created tenant department data.
 * @param {Object} tenantDepartmentData - The data of the tenant department to be created. Contains details like name, code, and parent ID.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const deleteTenantDepartmentData = async (
  tenantDepartmentId,
  setLoading,
  setError,
) => {
  if (!tenantDepartmentId) return;

  return apiCall({
    method: "delete",
    url: `${apiPrefix}/tenant_departments/${tenantDepartmentId}`,
    setLoading,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "部署");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * postAdminUserData - Sends a new admin user's data to the API for creation.
 *
 * This function makes an API call to create a new admin user using the provided `userData`.
 * The data is sent to the API endpoint to create a system admin login.
 *
 * @async
 * @function postAdminUserData
 * @param {Object} userData - The admin user data to be created. Contains details such as name, email, and other relevant information.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const postAdminUserData = async (userData, setLoading, setError) => {
  if (!userData) return;

  return apiCall({
    method: "post",
    url: `${apiPrefix}/system_admin_logins`,
    data: userData,
    setLoading,
    onSuccess: (response) => {
      const responseMessage = handleResponse(response, "管理者");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getBroadcastGroupData - Fetches and sets the data of all broadcast groups from the API.
 *
 * This function makes an API call to retrieve a list of all broadcast groups. The retrieved data is mapped to a simplified format
 * and set to the state using the provided `setBroadcastGroupData` function.
 *
 * @async
 * @function getBroadcastGroupData
 * @param {Function} setBroadcastGroupData - Setter function to update the state with the fetched broadcast group data array.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */
export const getBroadcastGroupData = async (
  setBroadcastGroupData,
  setLoading,
  setError,
) => {
  return apiCall({
    method: "get",
    url: `${apiPrefix}/broadcast_groups/`,
    setLoading,
    onSuccess: (response) => {
      const broadcastGroups = response.data.broadcast_groups.map((group) => ({
        id: group.id,
        uid: group.uid,
        name: group.name,
      }));

      setBroadcastGroupData(broadcastGroups);
      const responseMessage = handleResponse(response, "ロール");
      return responseMessage;
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getFilteredBroadcastGroupData - Fetches and sets broadcast group data filtered by a search query from the API.
 *
 * This function makes an API call to retrieve a list of broadcast groups based on the provided search query.
 * If no query is provided, it fetches all broadcast groups. The retrieved data is mapped to a simplified format
 * and set to the state using the provided `setBroadcastUserData` function.
 *
 * @async
 * @function getFilteredBroadcastGroupData
 * @param {Function} setBroadcastUserData - Setter function to update the state with the fetched broadcast group data array.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {string} query - The search query used to filter the broadcast groups.
 * @returns {Promise<void>} -
 * Returns a promise that resolves when the broadcast group data has been fetched and set.
 */

export const getFilteredBroadcastGroupData = async (
  setBroadcastUserData,
  setLoading,
  setError,
  query: string,
) => {
  const uri = query
    ? `${apiPrefix}/broadcast_groups/?${query}`
    : `${apiPrefix}/broadcast_groups/`;

  return apiCall({
    method: "get",
    url: uri,
    setLoading,
    onSuccess: (response) => {
      const groups = response.data.map((group) => ({
        id: group.id,
        uid: group.uid,
        name: group.name,
      }));
      setBroadcastUserData(groups);
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getRoleData - Fetches and sets the data of all roles from the API.
 *
 * This function makes an API call to retrieve a list of all roles. The retrieved role data is mapped to a simplified format
 * and set to the state using the provided `setRoleData` function.
 *
 * @async
 * @function getRoleData
 * @param {Function} setRoleData - Setter function to update the state with the fetched role data array.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getRoleData = async (setRoleData, setError, setLoading) => {
  return apiCall({
    method: "get",
    url: `${apiPrefix}/roles/`,
    setLoading,
    onSuccess: (response) => {
      const roleData = response.data.map((role) => ({
        id: role.id,
        name: role.name,
        type: role.role_type,
      }));

      setRoleData(roleData);
      return handleResponse(response, "ロール");
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getSkillTagData - Fetches and sets a list of all available skill tags from the API.
 *
 * This function makes an API call to retrieve a list of all skill tags. The retrieved skill tag data is mapped to a simplified format
 * and set to the state using the provided `setSkillTags` function.
 *
 * @async
 * @function getSkillTagData
 * @param {Function} setSkillTags - Setter function to update the state with the fetched skill tag data array.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getSkillTagData = async (setSkillTags, setError, setLoading) => {
  return apiCall({
    method: "get",
    url: `${apiPrefix}/skill_tags`,
    setLoading,
    onSuccess: (response) => {
      const tags = response.data.map((tag) => ({
        id: tag.id,
        name: tag.name,
        description: tag.description,
      }));

      setSkillTags(tags);
      return handleResponse(response, "スキルタグ");
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getFilteredSkilltagData - Fetches and sets a list of skill tags filtered by a search query from the API.
 *
 * This function makes an API call to retrieve a list of skill tags based on the provided search query.
 * If no query is provided, it fetches all skill tags. The retrieved skill tag data is mapped to a simplified format
 * and set to the state using the provided `setSkillTags` function.
 *
 * @async
 * @function getFilteredSkilltagData
 * @param {Function} setSkillTags - Setter function to update the state with the fetched skill tag data array.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {string} query - The search query used to filter the skill tags.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getFilteredSkilltagData = async (
  setSkillTags,
  setError,
  setLoading,
  query,
) => {
  const uri = query
    ? `${apiPrefix}/skill_tags/?${query}`
    : `${apiPrefix}/skill_tags/`;

  return apiCall({
    method: "get",
    url: uri,
    setLoading,
    onSuccess: (response) => {
      const tags = response.data.map((tag) => ({
        id: tag.id,
        name: tag.name,
        description: tag.description,
      }));

      setSkillTags(tags);
      return handleResponse(response, "スキルタグ");
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getContactsData - Fetches and sets the data of all contacts from the API.
 *
 * This function makes an API call to retrieve a list of all contacts. The retrieved contact data is mapped to a simplified format
 * and set to the state using the provided `setContactData` function.
 *
 * @async
 * @function getContactsData
 * @param {Function} setContactData - Setter function to update the state with the fetched contact data array.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getContactsData = async (setContactData, setError, setLoading) => {
  return apiCall({
    method: "get",
    url: `${apiPrefix}/contacts/users?user_type=2`,
    setLoading,
    onSuccess: (response) => {
      const contact = response.data.contacts.map(MapContact);
      setContactData(contact);
      return handleResponse(response, "電話帳");
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};
/**
 * getContactData - Fetches and sets the data of a single contact from the API.
 *
 * This function makes an API call to retrieve detailed information about a specific contact identified by the provided `userId`.
 * The retrieved data is processed and set to the state using the provided `setContactData` function.
 *
 * @async
 * @function getContactData
 * @param {Function} setContactData - Setter function to update the state with the fetched contact data.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {string} userId - The unique identifier of the contact to fetch the data for.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getContactData = async (
  setContactData,
  setError,
  setLoading,
  userId,
) => {
  if (!userId) return;

  return apiCall({
    method: "get",
    url: `${apiPrefix}/users/show_contact/${userId}`,
    setLoading,
    onSuccess: (response) => {
      const contact = response.data;

      let position_name =
        Array.isArray(response.data.external_departments) &&
        response.data.external_departments[0]?.external_name
          ? response.data.external_departments[0].external_name
          : Array.isArray(response.data.internal_departments) &&
            response.data.internal_departments[0]?.external_name
          ? response.data.internal_departments[0].external_name
          : "";

      const user = MapContact(contact, position_name);

      setContactData(user);
      return handleResponse(response, "電話帳");
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getFilteredContactsData - Fetches and sets filtered contact data based on a search query from the API.
 *
 * This function makes an API call to retrieve a list of contacts based on the provided search query.
 * The retrieved contact data is mapped to a simplified format and set to the state using the provided `setContactsData` function.
 *
 * @async
 * @function getFilteredContactsData
 * @param {Function} setContactsData - Setter function to update the state with the fetched filtered contact data array.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {string} query - The search query used to filter the contacts.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getFilteredContactsData = async (
  setContactsData,
  setLoading,
  setError,
  query: string,
) => {
  const params = new URLSearchParams();
  const uri = `${apiPrefix}/users/?${query}&${params.toString()}`;

  return apiCall({
    method: "get",
    url: uri,
    setLoading,
    onSuccess: (response) => {
      const contacts = response.data.contacts.map(MapContact);
      setContactsData(contacts);
      return handleResponse(response, "電話帳");
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getNotificationsData - Fetches and sets a list of notifications from the API with optional pagination and filtering.
 *
 * This function makes an API call to retrieve a list of notifications. It supports pagination and an optional filter to
 * fetch only active notifications when called from the homepage. The retrieved notification data is mapped to a simplified format
 * and set to the state using the provided `setNotificationData` function.
 *
 * @async
 * @function getNotificationsData
 * @param {Function} setNotificationData - Setter function to update the state with the fetched notification data array.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {boolean} fromHomePage - Flag to determine whether to fetch only active notifications for homepage display.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getNotificationsData = async (
  setNotificationData,
  setTotalNotifications,
  setLoading,
  setError,
  fromHomePage,
  currentPage,
  itemsPerPage,
  query = "",
) => {
  const queryString = query || "";
  const uri = fromHomePage
    ? `${apiPrefix}/notifications?status=true&current_page=${currentPage}&per_page=${itemsPerPage}${queryString}`
    : `${apiPrefix}/notifications?current_page=${currentPage}&per_page=${itemsPerPage}${queryString}`;

  return apiCall({
    method: "get",
    url: uri,
    setLoading,
    onSuccess: (response) => {
      const notifications = response.data.notifications.map(MapNotification);

      setNotificationData(notifications);
      setTotalNotifications(response.data.total_count);
      return handleResponse(response, "お知らせ");
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * getNotificationData - Fetches and sets the data of a single notification from the API.
 *
 * This function makes an API call to retrieve detailed information about a specific notification identified by the provided `notificationId`.
 * The retrieved data is processed and set to the state using the provided `setNotificationData` function.
 *
 * @async
 * @function getNotificationData
 * @param {Function} setNotificationData - Setter function to update the state with the fetched notification data.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {string} notificationId - The unique identifier of the notification to fetch the data for.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const getNotificationData = async (
  setNotificationData,
  setLoading,
  setError,
  notificationId,
) => {
  if (!notificationId) return;

  return apiCall({
    method: "get",
    url: `${apiPrefix}/notifications/${notificationId}`,
    setLoading,
    onSuccess: (response) => {
      const data = response.data;
      const notification = MapNotification(data);

      setNotificationData(notification);
      return handleResponse(response, "お知らせ");
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * putNotificationData - Updates a single notification's data in the API.
 *
 * This function makes an API call to update an existing notification identified by the provided `notificationId` using the supplied `notificationData`.
 * The updated notification data is sent to the API endpoint using a PUT request to modify the notification record.
 *
 * @async
 * @function putNotificationData
 * @param {Object} notificationData - The updated notification data to be sent to the API. Contains details such as title, content, and status.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {string} notificationId - The unique identifier of the notification to be updated.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const putNotificationData = async (
  notificationData,
  setLoading,
  setError,
  notificationId,
) => {
  if (!notificationId || !notificationData) return;

  return apiCall({
    method: "put",
    url: `${apiPrefix}/notifications/${notificationId}`,
    data: notificationData,
    setLoading,
    onSuccess: (response) => {
      return handleResponse(response, "お知らせ");
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * postNotificationData - Creates a new notification in the API.
 *
 * This function makes an API call to create a new notification using the provided `notificationData`.
 * The notification data is sent to the API endpoint using a POST request to create a new notification record.
 *
 * @async
 * @function postNotificationData
 * @param {Object} notificationData - The notification data to be created. Contains details such as title, content, and status.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const postNotificationData = async (
  notificationData,
  setLoading,
  setError,
) => {
  if (!notificationData) return;

  return apiCall({
    method: "post",
    url: `${apiPrefix}/notifications/`,
    data: notificationData,
    setLoading,
    onSuccess: (response) => {
      return handleResponse(response, "お知らせ");
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};
// yobery testing

export const getYoberyBroadcastGroup = async (
  setBroadcastGroupData,
  setLoading,
  setError,
) => {
  let response;
  let responseMessage;
  try {
    yoberyApi.defaults.headers = yoberyHeader;

    setLoading(true);
    response = await yoberyApi.post(`${yoberyApiPrefix}/broadcast_groups/read`);

    const broadCastgroupData = response.data.payload.broadcast_groups.map(
      (data) => ({
        broadcast_group_id: data.broadcast_group_id,
        broadcast_group_name: data.broadcast_group_name,
      }),
    );

    setBroadcastGroupData(broadCastgroupData);
  } catch (err) {
    console.error(err.message);
    const errorResponse =
      err.response.data.errors || err.response.data.error || err.message;
    responseMessage = { status: err.status, message: errorResponse };
    setError(errorResponse);
  } finally {
    setLoading(false);
    return response;
  }
};

// yobery testing

export const postYoberyBroadcastGroup = async (
  yoberyData,
  setLoading,
  setError,
) => {
  if (yoberyData) {
    let response;
    let responseMessage;

    try {
      yoberyApi.defaults.headers = yoberyHeader;

      setLoading(true);
      response = await yoberyApi.post(
        `${yoberyApiPrefix}/broadcast_groups/create`,
        yoberyData,
      );
      responseMessage = handleResponse(response, "一斉発信グループ");
    } catch (err) {
      console.error(err.message);
      const errorResponse =
        err.response.data.errors || err.response.data.error || err.message;
      responseMessage = { status: err.status, message: errorResponse };
      setError(errorResponse);
    } finally {
      setLoading(false);
      return responseMessage;
    }
  }
};

// add to put for broadcast group

export const patchYoberyBroadcastGroup = async (
  yoberyData,
  setLoading,
  setError,
) => {
  if (yoberyData) {
    let response;
    let responseMessage;
    try {
      yoberyApi.defaults.headers = yoberyHeader;

      setLoading(true);
      response = await yoberyApi.patch(
        `${yoberyApiPrefix}/broadcast_groups/update`,
        yoberyData,
      );
    } catch (err) {
      console.error(err.message);
      const errorResponse =
        err.response.data.errors || err.response.data.error || err.message;
      responseMessage = { status: err.status, message: errorResponse };
      setError(errorResponse);
    } finally {
      setLoading(false);
      responseMessage = handleResponse(response, "一斉発信グループ");
      return responseMessage;
    }
  }
};
//

export const deleteYoberyBroadcastGroup = async (
  yoberyData,
  setLoading,
  setError,
) => {
  if (yoberyData) {
    let response;
    let responseMessage;
    try {
      yoberyApi.defaults.headers = yoberyHeader;

      setLoading(true);
      response = await yoberyApi.delete(
        `${yoberyApiPrefix}/broadcast_groups/delete`,
        {
          data: yoberyData,
        },
      );
    } catch (err) {
      console.error(err.message);
      const errorResponse =
        err.response.data.errors || err.response.data.error || err.message;
      responseMessage = { status: err.status, message: errorResponse };
      setError(errorResponse);
    } finally {
      setLoading(false);
      responseMessage = handleResponse(response, "一斉発信グループ");
      return responseMessage;
    }
  }
};

export const postYoberyUserData = async (yoberyData, setLoading, setError) => {
  if (yoberyData) {
    let response;
    let responseMessage;
    try {
      yoberyApi.defaults.headers = yoberyHeader;

      setLoading(true);
      response = await yoberyApi.post(
        `${yoberyApiPrefix}/users/create`,
        yoberyData,
      );
    } catch (err) {
      console.error(err.message);
      const errorResponse =
        err.response.data.errors || err.response.data.error || err.message;
      responseMessage = { status: err.status, message: errorResponse };
      setError(errorResponse);
    } finally {
      responseMessage = handleResponse(response, "ユーザ");
      setLoading(false);
      return responseMessage;
    }
  }
};

//

// add to put for broadcast group

export const patchYoberyUserData = async (yoberyData, setLoading, setError) => {
  if (yoberyData) {
    let response;
    let responseMessage;
    try {
      yoberyApi.defaults.headers = yoberyHeader;

      setLoading(true);
      response = await yoberyApi.patch(
        `${yoberyApiPrefix}/users/update`,
        yoberyData,
      );
      responseMessage = handleResponse(response, "YOBERYユーザ");
    } catch (err) {
      console.error(err.message);
      const errorResponse =
        err.response.data.errors || err.response.data.error || err.message;
      responseMessage = { status: err.status, message: errorResponse };
      setError(errorResponse);
    } finally {
      setLoading(false);
      return responseMessage;
    }
  }
};
//

/**
 * resetUserPassword - Resets the password for a user in the API.
 *
 * This function makes an API call to update the password for a user. If no `id` is provided (default: 0),
 * it updates the password for the currently authenticated user. Otherwise, it updates the password for the specified user.
 *
 * @async
 * @function resetUserPassword
 * @param {string} userPassword - The new password to be set for the user.
 * @param {Function} setLoading - Setter function to indicate the loading state during the API call.
 * @param {Function} setError - Setter function to capture and display any error messages.
 * @param {number} [id=0] - The unique identifier of the user whose password is to be reset. Defaults to 0 for the current user.
 * @returns {Promise<{status: number, message: string}>} -
 * Returns a JSON object containing the status and message of the API response.
 */

export const resetUserPassword = async (
  userPassword,
  setLoading,
  setError,
  id = 0,
) => {
  if (!userPassword) return;

  const body = {
    password: userPassword,
  };

  const url =
    id === 0 ? `${apiPrefix}/password/` : `${apiPrefix}/password/${id}`;

  return apiCall({
    method: "put",
    url,
    data: body,
    setLoading,
    onSuccess: (response) => {
      return handleResponse(response, "パスワード変更");
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};
/**
 * uploadUserImage - Uploads a new profile image for a user to the API.
 *
 * This function makes an API call to upload a new profile image for a user identified by the provided `id`.
 * The image data is sent using a multipart/form-data request.
 *
 * @async
 * @function uploadUserImage
 * @param {string} id - The unique identifier of the user whose image is to be uploaded.
 * @param {FileList} userImageData - The image file to be uploaded for the user.
 * @returns {Promise<void>} - Returns a promise that resolves when the image has been successfully uploaded.
 */

export const uploadUserImage = async (id, userImageData) => {
  const header = store.getState().api.headers;
  api.defaults.headers = header;
  let response;
  let translatedMessage;

  const formData = new FormData();
  formData.append("image", userImageData[0]);

  try {
    response = await api.put(
      `${apiPrefix}/users/${id}/upload_image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    translatedMessage = handleResponse(response);
  } catch (err) {
    translatedMessage = TranslateErrorMessage(err.data);
  } finally {
    return translatedMessage;
  }
};

/**
 * deleteUserImage - Deletes the profile image of a user in the API.
 *
 * This function makes an API call to delete the profile image for a user identified by the provided `id`.
 *
 * @async
 * @function deleteUserImage
 * @param {string} id - The unique identifier of the user whose image is to be deleted.
 * @returns {Promise<void>} - Returns a promise that resolves when the image has been successfully deleted.
 */

export const deleteUserImage = async (id, setLoading, setError) => {
  if (!id) return;

  return apiCall({
    method: "delete",
    url: `${apiPrefix}/users/${id}/delete_image`,
    setLoading,
    onSuccess: (response) => {
      return handleResponse(response, "画像削除");
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

/**
 * uploadCardImage - Uploads a card image for a specific contact to the API.
 *
 * This function makes an API call to upload a new card image for a contact identified by the provided `id`.
 * The image data is sent using a multipart/form-data request.
 *
 * @async
 * @function uploadCardImage
 * @param {string} id - The unique identifier of the contact whose card image is to be uploaded.
 * @param {File} cardImageData - The image file to be uploaded for the contact's card. It should be a single file object, not a FileList.
 * @returns {Promise<void>} - Returns a promise that resolves when the card image has been successfully uploaded.
 */

export const uploadCardImage = async (id, cardImageData) => {
  const header = store.getState().api.headers;
  let translatedMessage;
  let status;
  let response;
  api.defaults.headers = header;

  const formData = new FormData();
  formData.append("image", cardImageData);

  try {
    response = await api.put(
      `${apiPrefix}/users/${id}/upload_card_image`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    status = response.status;
    translatedMessage = TranslateErrorMessage(response);
  } catch (err) {
    status = err.status;
    translatedMessage = TranslateErrorMessage(err.response);
  } finally {
    return { status, translatedMessage };
  }
};

/**
 * deleteCardImage - Deletes a specific card image for a contact in the API.
 *
 * This function makes an API call to delete a card image for a contact identified by the provided `id` and `index`.
 * The `index` parameter indicates the position of the card image in the contact's list of images.
 *
 * @async
 * @function deleteCardImage
 * @param {string} id - The unique identifier of the contact whose card image is to be deleted.
 * @param {number} index - The index position of the card image to be deleted from the contact's list of images.
 * @returns {Promise<void>} - Returns a promise that resolves when the card image has been successfully deleted.
 */

export const deleteCardImage = async (id, index, setLoading, setError) => {
  const deleteUrl = `${apiPrefix}/users/${id}/delete_image/${index}`;

  return apiCall({
    method: "delete",
    url: deleteUrl,
    setLoading,
    onSuccess: (response) => {
      return handleResponse(response, "カード画像削除");
    },
    onError: (errorResponse) => {
      const error =
        errorResponse.errors || errorResponse.error || errorResponse;
      setError(error);
    },
  });
};

export const getUploadStatus = async (request, total_users) => {
  const deleteUrl = `${apiPrefix}/user/status?request_id=${request}&total_users=${total_users}}`;

  let response;

  response = await api.get(deleteUrl);

  return response;
};
